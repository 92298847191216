(function () {
    'use strict';

    var
        /**
         * anonymous function before scripts loaded
         */
        functionName = window['EmailcampaignsTracker'],
        existingFunction = window[functionName];

    /* spencermountain/spacetime 7.1.0 Apache 2.0 */
    (function (global, factory) {
      typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
      typeof define === 'function' && define.amd ? define(factory) :
      (global = typeof globalThis !== 'undefined' ? globalThis : global || self, global.spacetime = factory());
    })(this, (function () { 'use strict';
    
      const MSEC_IN_HOUR = 60 * 60 * 1000;
    
      //convert our local date syntax a javascript UTC date
      const toUtc = (dstChange, offset, year) => {
        const [month, rest] = dstChange.split('/');
        const [day, hour] = rest.split(':');
        return Date.UTC(year, month - 1, day, hour) - offset * MSEC_IN_HOUR
      };
    
      // compare epoch with dst change events (in utc)
      const inSummerTime = (epoch, start, end, summerOffset, winterOffset) => {
        const year = new Date(epoch).getUTCFullYear();
        const startUtc = toUtc(start, winterOffset, year);
        const endUtc = toUtc(end, summerOffset, year);
        // simple number comparison now
        return epoch >= startUtc && epoch < endUtc
      };
    
      var inSummerTime$1 = inSummerTime;
    
      // this method avoids having to do a full dst-calculation on every operation
      // it reproduces some things in ./index.js, but speeds up spacetime considerably
      const quickOffset = s => {
        let zones = s.timezones;
        let obj = zones[s.tz];
        if (obj === undefined) {
          console.warn("Warning: couldn't find timezone " + s.tz);
          return 0
        }
        if (obj.dst === undefined) {
          return obj.offset
        }
    
        //get our two possible offsets
        let jul = obj.offset;
        let dec = obj.offset + 1; // assume it's the same for now
        if (obj.hem === 'n') {
          dec = jul - 1;
        }
        let split = obj.dst.split('->');
        let inSummer = inSummerTime$1(s.epoch, split[0], split[1], jul, dec);
        if (inSummer === true) {
          return jul
        }
        return dec
      };
      var quickOffset$1 = quickOffset;
    
      var data = {
        "9|s": "2/dili,2/jayapura",
        "9|n": "2/chita,2/khandyga,2/pyongyang,2/seoul,2/tokyo,11/palau,japan,rok",
        "9.5|s|04/03:03->10/02:02": "4/adelaide,4/broken_hill,4/south,4/yancowinna",
        "9.5|s": "4/darwin,4/north",
        "8|s|03/08:01->10/04:00": "12/casey",
        "8|s": "2/kuala_lumpur,2/makassar,2/singapore,4/perth,2/ujung_pandang,4/west,singapore",
        "8|n": "2/brunei,2/choibalsan,2/hong_kong,2/irkutsk,2/kuching,2/macau,2/manila,2/shanghai,2/taipei,2/ulaanbaatar,2/chongqing,2/chungking,2/harbin,2/macao,2/ulan_bator,hongkong,prc,roc",
        "8.75|s": "4/eucla",
        "7|s": "12/davis,2/jakarta,9/christmas",
        "7|n": "2/bangkok,2/barnaul,2/hovd,2/krasnoyarsk,2/novokuznetsk,2/novosibirsk,2/phnom_penh,2/pontianak,2/ho_chi_minh,2/tomsk,2/vientiane,2/saigon",
        "6|s": "12/vostok",
        "6|n": "2/almaty,2/bishkek,2/dhaka,2/omsk,2/qyzylorda,2/qostanay,2/thimphu,2/urumqi,9/chagos,2/dacca,2/kashgar,2/thimbu",
        "6.5|n": "2/yangon,9/cocos,2/rangoon",
        "5|s": "12/mawson,9/kerguelen",
        "5|n": "2/aqtau,2/aqtobe,2/ashgabat,2/atyrau,2/dushanbe,2/karachi,2/oral,2/samarkand,2/tashkent,2/yekaterinburg,9/maldives,2/ashkhabad",
        "5.75|n": "2/katmandu,2/kathmandu",
        "5.5|n": "2/kolkata,2/colombo,2/calcutta",
        "4|s": "9/reunion",
        "4|n": "2/baku,2/dubai,2/muscat,2/tbilisi,2/yerevan,8/astrakhan,8/samara,8/saratov,8/ulyanovsk,8/volgograd,2/volgograd,9/mahe,9/mauritius",
        "4.5|n|03/22:00->09/21:24": "2/tehran,iran",
        "4.5|n": "2/kabul",
        "3|s": "12/syowa,9/antananarivo",
        "3|n|03/27:03->10/30:04": "2/famagusta,2/nicosia,8/athens,8/bucharest,8/helsinki,8/kiev,8/mariehamn,8/riga,8/sofia,8/tallinn,8/uzhgorod,8/vilnius,8/zaporozhye",
        "3|n|03/27:02->10/30:03": "8/chisinau,8/tiraspol",
        "3|n|03/27:00->10/29:24": "2/beirut",
        "3|n|03/26:00->10/28:01": "2/gaza,2/hebron",
        "3|n|03/25:02->10/30:02": "2/jerusalem,2/tel_aviv,israel",
        "3|n|03/25:00->10/27:24": "2/damascus",
        "3|n|02/25:00->10/28:01": "2/amman",
        "3|n": "0/addis_ababa,0/asmera,0/dar_es_salaam,0/djibouti,0/juba,0/kampala,0/mogadishu,0/nairobi,2/aden,2/baghdad,2/bahrain,2/kuwait,2/qatar,2/riyadh,8/istanbul,8/kirov,8/minsk,8/moscow,8/simferopol,9/comoro,9/mayotte,2/istanbul,turkey,w-su",
        "2|s|03/27:02->10/30:02": "12/troll",
        "2|s": "0/gaborone,0/harare,0/johannesburg,0/lubumbashi,0/lusaka,0/maputo,0/maseru,0/mbabane",
        "2|n|03/27:02->10/30:03": "0/ceuta,arctic/longyearbyen,8/amsterdam,8/andorra,8/belgrade,8/berlin,8/bratislava,8/brussels,8/budapest,8/busingen,8/copenhagen,8/gibraltar,8/ljubljana,8/luxembourg,8/madrid,8/malta,8/monaco,8/oslo,8/paris,8/podgorica,8/prague,8/rome,8/san_marino,8/sarajevo,8/skopje,8/stockholm,8/tirane,8/vaduz,8/vatican,8/vienna,8/warsaw,8/zagreb,8/zurich,3/jan_mayen,poland",
        "2|n": "0/blantyre,0/bujumbura,0/cairo,0/khartoum,0/kigali,0/tripoli,8/kaliningrad,egypt,libya",
        "1|s": "0/brazzaville,0/kinshasa,0/luanda,0/windhoek",
        "1|n|03/27:03->05/08:02": "0/casablanca,0/el_aaiun",
        "1|n|03/27:01->10/30:02": "3/canary,3/faroe,3/madeira,8/dublin,8/guernsey,8/isle_of_man,8/jersey,8/lisbon,8/london,3/faeroe,eire,8/belfast,gb,gb-eire,portugal",
        "1|n": "0/algiers,0/bangui,0/douala,0/lagos,0/libreville,0/malabo,0/ndjamena,0/niamey,0/porto-novo,0/tunis",
        "14|n": "11/kiritimati",
        "13|s|04/04:04->09/26:03": "11/apia",
        "13|s|01/15:02->11/05:03": "11/tongatapu",
        "13|n": "11/enderbury,11/fakaofo",
        "12|s|04/03:03->09/25:02": "12/mcmurdo,11/auckland,12/south_pole,nz",
        "12|s|01/17:03->11/14:02": "11/fiji",
        "12|n": "2/anadyr,2/kamchatka,2/srednekolymsk,11/funafuti,11/kwajalein,11/majuro,11/nauru,11/tarawa,11/wake,11/wallis,kwajalein",
        "12.75|s|04/03:03->04/03:02": "11/chatham,nz-chat",
        "11|s|04/03:03->10/02:02": "12/macquarie",
        "11|s": "11/bougainville",
        "11|n": "2/magadan,2/sakhalin,11/efate,11/guadalcanal,11/kosrae,11/noumea,11/pohnpei,11/ponape",
        "11.5|n|04/03:03->10/02:02": "11/norfolk",
        "10|s|04/03:03->10/02:02": "4/currie,4/hobart,4/melbourne,4/sydney,4/act,4/canberra,4/nsw,4/tasmania,4/victoria",
        "10|s": "12/dumontdurville,4/brisbane,4/lindeman,11/port_moresby,4/queensland",
        "10|n": "2/ust-nera,2/vladivostok,2/yakutsk,11/guam,11/saipan,11/chuuk,11/truk,11/yap",
        "10.5|s|04/03:01->10/02:02": "4/lord_howe,4/lhi",
        "0|n|03/27:00->10/30:01": "1/scoresbysund,3/azores",
        "0|n": "0/abidjan,0/accra,0/bamako,0/banjul,0/bissau,0/conakry,0/dakar,0/freetown,0/lome,0/monrovia,0/nouakchott,0/ouagadougou,0/sao_tome,1/danmarkshavn,3/reykjavik,3/st_helena,13/gmt,13/utc,0/timbuktu,13/uct,gmt+0,gmt-0,gmt0,13/greenwich,greenwich,iceland,uct,utc,universal,zulu",
        "-9|n|03/13:02->11/06:02": "1/adak,1/atka,us/aleutian",
        "-9|n": "11/gambier",
        "-9.5|n": "11/marquesas",
        "-8|n|03/13:02->11/06:02": "1/anchorage,1/juneau,1/metlakatla,1/nome,1/sitka,1/yakutat,us/alaska",
        "-8|n": "11/pitcairn",
        "-7|n|03/13:02->11/06:02": "1/los_angeles,1/santa_isabel,1/tijuana,1/vancouver,1/ensenada,6/pacific,10/bajanorte,us/pacific",
        "-7|n|03/08:02->11/01:01": "1/dawson,1/whitehorse,6/yukon",
        "-7|n": "1/creston,1/dawson_creek,1/fort_nelson,1/hermosillo,1/phoenix,us/arizona",
        "-6|s|04/02:22->09/03:22": "11/easter,7/easterisland",
        "-6|n|04/03:02->10/30:02": "1/chihuahua,1/mazatlan,10/bajasur",
        "-6|n|03/13:02->11/06:02": "1/boise,1/cambridge_bay,1/denver,1/edmonton,1/inuvik,1/ojinaga,1/yellowknife,1/shiprock,6/mountain,navajo,us/mountain",
        "-6|n": "1/belize,1/costa_rica,1/el_salvador,1/guatemala,1/managua,1/regina,1/swift_current,1/tegucigalpa,11/galapagos,6/saskatchewan",
        "-5|s": "1/lima,1/rio_branco,1/porto_acre,5/acre",
        "-5|n|04/03:02->10/30:02": "1/bahia_banderas,1/merida,1/mexico_city,1/monterrey,10/general",
        "-5|n|03/13:02->11/06:02": "1/chicago,1/matamoros,1/menominee,1/rainy_river,1/rankin_inlet,1/resolute,1/winnipeg,1/indiana/knox,1/indiana/tell_city,1/north_dakota/beulah,1/north_dakota/center,1/north_dakota/new_salem,1/knox_in,6/central,us/central,us/indiana-starke",
        "-5|n|03/12:03->11/05:01": "1/north_dakota",
        "-5|n": "1/bogota,1/cancun,1/cayman,1/coral_harbour,1/eirunepe,1/guayaquil,1/jamaica,1/panama,1/atikokan,jamaica",
        "-4|s|05/13:23->08/13:01": "12/palmer",
        "-4|s|04/02:24->09/04:00": "1/santiago,7/continental",
        "-4|s|03/26:24->10/02:00": "1/asuncion",
        "-4|s|02/16:24->11/03:00": "1/campo_grande,1/cuiaba",
        "-4|s": "1/la_paz,1/manaus,5/west",
        "-4|n|03/13:02->11/06:02": "1/detroit,1/grand_turk,1/indianapolis,1/iqaluit,1/louisville,1/montreal,1/nassau,1/new_york,1/nipigon,1/pangnirtung,1/port-au-prince,1/thunder_bay,1/toronto,1/indiana/marengo,1/indiana/petersburg,1/indiana/vevay,1/indiana/vincennes,1/indiana/winamac,1/kentucky/monticello,1/fort_wayne,1/indiana/indianapolis,1/kentucky/louisville,6/eastern,us/east-indiana,us/eastern,us/michigan",
        "-4|n|03/13:00->11/06:01": "1/havana,cuba",
        "-4|n|03/12:03->11/05:01": "1/indiana,1/kentucky",
        "-4|n": "1/anguilla,1/antigua,1/aruba,1/barbados,1/blanc-sablon,1/boa_vista,1/caracas,1/curacao,1/dominica,1/grenada,1/guadeloupe,1/guyana,1/kralendijk,1/lower_princes,1/marigot,1/martinique,1/montserrat,1/port_of_spain,1/porto_velho,1/puerto_rico,1/santo_domingo,1/st_barthelemy,1/st_kitts,1/st_lucia,1/st_thomas,1/st_vincent,1/tortola,1/virgin",
        "-3|s": "1/argentina,1/buenos_aires,1/catamarca,1/cordoba,1/fortaleza,1/jujuy,1/mendoza,1/montevideo,1/punta_arenas,1/sao_paulo,12/rothera,3/stanley,1/argentina/la_rioja,1/argentina/rio_gallegos,1/argentina/salta,1/argentina/san_juan,1/argentina/san_luis,1/argentina/tucuman,1/argentina/ushuaia,1/argentina/comodrivadavia,1/argentina/buenos_aires,1/argentina/catamarca,1/argentina/cordoba,1/argentina/jujuy,1/argentina/mendoza,1/argentina/rosario,5/east",
        "-3|n|03/13:02->11/06:02": "1/glace_bay,1/goose_bay,1/halifax,1/moncton,1/thule,3/bermuda,6/atlantic",
        "-3|n": "1/araguaina,1/bahia,1/belem,1/cayenne,1/maceio,1/paramaribo,1/recife,1/santarem",
        "-2|n|03/26:22->10/29:23": "1/nuuk,1/godthab",
        "-2|n|03/13:02->11/06:02": "1/miquelon",
        "-2|n": "1/noronha,3/south_georgia,5/denoronha",
        "-2.5|n|03/13:02->11/06:02": "1/st_johns,6/newfoundland",
        "-1|n": "3/cape_verde",
        "-11|n": "11/midway,11/niue,11/pago_pago,11/samoa,us/samoa",
        "-10|n": "11/honolulu,11/johnston,11/rarotonga,11/tahiti,us/hawaii"
      };
    
      //prefixes for iana names..
      var prefixes = [
        'africa',
        'america',
        'asia',
        'atlantic',
        'australia',
        'brazil',
        'canada',
        'chile',
        'europe',
        'indian',
        'mexico',
        'pacific',
        'antarctica',
        'etc'
      ];
    
      let all = {};
      Object.keys(data).forEach((k) => {
        let split = k.split('|');
        let obj = {
          offset: Number(split[0]),
          hem: split[1]
        };
        if (split[2]) {
          obj.dst = split[2];
        }
        let names = data[k].split(',');
        names.forEach((str) => {
          str = str.replace(/(^[0-9]+)\//, (before, num) => {
            num = Number(num);
            return prefixes[num] + '/'
          });
          all[str] = obj;
        });
      });
    
      all.utc = {
        offset: 0,
        hem: 'n' //default to northern hemisphere - (sorry!)
      };
    
      //add etc/gmt+n
      for (let i = -14; i <= 14; i += 0.5) {
        let num = i;
        if (num > 0) {
          num = '+' + num;
        }
        let name = 'etc/gmt' + num;
        all[name] = {
          offset: i * -1, //they're negative!
          hem: 'n' //(sorry)
        };
        name = 'utc/gmt' + num; //this one too, why not.
        all[name] = {
          offset: i * -1,
          hem: 'n'
        };
      }
    
      var zones = all;
    
      //find the implicit iana code for this machine.
      //safely query the Intl object
      //based on - https://bitbucket.org/pellepim/jstimezonedetect/src
      const fallbackTZ = 'utc'; //
    
      //this Intl object is not supported often, yet
      const safeIntl = () => {
        if (typeof Intl === 'undefined' || typeof Intl.DateTimeFormat === 'undefined') {
          return null
        }
        let format = Intl.DateTimeFormat();
        if (typeof format === 'undefined' || typeof format.resolvedOptions === 'undefined') {
          return null
        }
        let timezone = format.resolvedOptions().timeZone;
        if (!timezone) {
          return null
        }
        return timezone.toLowerCase()
      };
    
      const guessTz = () => {
        let timezone = safeIntl();
        if (timezone === null) {
          return fallbackTZ
        }
        return timezone
      };
      //do it once per computer
      var guessTz$1 = guessTz;
    
      const isOffset = /(\-?[0-9]+)h(rs)?/i;
      const isNumber = /(\-?[0-9]+)/;
      const utcOffset = /utc([\-+]?[0-9]+)/i;
      const gmtOffset = /gmt([\-+]?[0-9]+)/i;
    
      const toIana = function (num) {
        num = Number(num);
        if (num >= -13 && num <= 13) {
          num = num * -1; //it's opposite!
          num = (num > 0 ? '+' : '') + num; //add plus sign
          return 'etc/gmt' + num
        }
        return null
      };
    
      const parseOffset$2 = function (tz) {
        // '+5hrs'
        let m = tz.match(isOffset);
        if (m !== null) {
          return toIana(m[1])
        }
        // 'utc+5'
        m = tz.match(utcOffset);
        if (m !== null) {
          return toIana(m[1])
        }
        // 'GMT-5' (not opposite)
        m = tz.match(gmtOffset);
        if (m !== null) {
          let num = Number(m[1]) * -1;
          return toIana(num)
        }
        // '+5'
        m = tz.match(isNumber);
        if (m !== null) {
          return toIana(m[1])
        }
        return null
      };
      var parseOffset$3 = parseOffset$2;
    
      const local = guessTz$1();
    
      //add all the city names by themselves
      const cities = Object.keys(zones).reduce((h, k) => {
        let city = k.split('/')[1] || '';
        city = city.replace(/_/g, ' ');
        h[city] = k;
        return h
      }, {});
    
      //try to match these against iana form
      const normalize$3 = (tz) => {
        tz = tz.replace(/ time/g, '');
        tz = tz.replace(/ (standard|daylight|summer)/g, '');
        tz = tz.replace(/\b(east|west|north|south)ern/g, '$1');
        tz = tz.replace(/\b(africa|america|australia)n/g, '$1');
        tz = tz.replace(/\beuropean/g, 'europe');
        tz = tz.replace(/\islands/g, 'island');
        return tz
      };
    
      // try our best to reconcile the timzone to this given string
      const lookupTz = (str, zones) => {
        if (!str) {
          return local
        }
        if (typeof str !== 'string') {
          console.error("Timezone must be a string - recieved: '", str, "'\n");
        }
        let tz = str.trim();
        // let split = str.split('/')
        //support long timezones like 'America/Argentina/Rio_Gallegos'
        // if (split.length > 2 && zones.hasOwnProperty(tz) === false) {
        //   tz = split[0] + '/' + split[1]
        // }
        tz = tz.toLowerCase();
        if (zones.hasOwnProperty(tz) === true) {
          return tz
        }
        //lookup more loosely..
        tz = normalize$3(tz);
        if (zones.hasOwnProperty(tz) === true) {
          return tz
        }
        //try city-names
        if (cities.hasOwnProperty(tz) === true) {
          return cities[tz]
        }
        // //try to parse '-5h'
        if (/[0-9]/.test(tz) === true) {
          let id = parseOffset$3(tz);
          if (id) {
            return id
          }
        }
    
        throw new Error(
          "Spacetime: Cannot find timezone named: '" + str + "'. Please enter an IANA timezone id."
        )
      };
      var findTz = lookupTz;
    
      //git:blame @JuliasCaesar https://www.timeanddate.com/date/leapyear.html
      function isLeapYear(year) { return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 }
      // unsurprisingly-nasty `typeof date` call
      function isDate(d) { return Object.prototype.toString.call(d) === '[object Date]' && !isNaN(d.valueOf()) }
      function isArray(input) { return Object.prototype.toString.call(input) === '[object Array]' }
      function isObject(input) { return Object.prototype.toString.call(input) === '[object Object]' }
      function isBoolean(input) { return Object.prototype.toString.call(input) === '[object Boolean]' }
    
      function zeroPad(str, len = 2) {
        let pad = '0';
        str = str + '';
        return str.length >= len ? str : new Array(len - str.length + 1).join(pad) + str
      }
    
      function titleCase$1(str) {
        if (!str) {
          return ''
        }
        return str[0].toUpperCase() + str.substr(1)
      }
    
      function ordinal(i) {
        let j = i % 10;
        let k = i % 100;
        if (j === 1 && k !== 11) {
          return i + 'st'
        }
        if (j === 2 && k !== 12) {
          return i + 'nd'
        }
        if (j === 3 && k !== 13) {
          return i + 'rd'
        }
        return i + 'th'
      }
    
      //strip 'st' off '1st'..
      function toCardinal(str) {
        str = String(str);
        str = str.replace(/([0-9])(st|nd|rd|th)$/i, '$1');
        return parseInt(str, 10)
      }
    
      //used mostly for cleanup of unit names, like 'months'
      function normalize$2(str = '') {
        str = str.toLowerCase().trim();
        str = str.replace(/ies$/, 'y'); //'centuries'
        str = str.replace(/s$/, '');
        str = str.replace(/-/g, '');
        if (str === 'day' || str === 'days') {
          return 'date'
        }
        if (str === 'min' || str === 'mins') {
          return 'minute'
        }
        return str
      }
    
      function getEpoch(tmp) {
        //support epoch
        if (typeof tmp === 'number') {
          return tmp
        }
        //suport date objects
        if (isDate(tmp)) {
          return tmp.getTime()
        }
        if (tmp.epoch) {
          return tmp.epoch
        }
        return null
      }
    
      //make sure this input is a spacetime obj
      function beADate(d, s) {
        if (isObject(d) === false) {
          return s.clone().set(d)
        }
        return d
      }
    
      function formatTimezone(offset, delimiter = '') {
        const sign = offset > 0 ? '+' : '-';
        const absOffset = Math.abs(offset);
        const hours = zeroPad(parseInt('' + absOffset, 10));
        const minutes = zeroPad((absOffset % 1) * 60);
        return `${sign}${hours}${delimiter}${minutes}`
      }
    
      const defaults$1 = {
        year: new Date().getFullYear(),
        month: 0,
        date: 1
      };
    
      //support [2016, 03, 01] format
      const parseArray$1 = (s, arr, today) => {
        if (arr.length === 0) {
          return s
        }
        let order = ['year', 'month', 'date', 'hour', 'minute', 'second', 'millisecond'];
        for (let i = 0; i < order.length; i++) {
          let num = arr[i] || today[order[i]] || defaults$1[order[i]] || 0;
          s = s[order[i]](num);
        }
        return s
      };
    
      //support {year:2016, month:3} format
      const parseObject$1 = (s, obj, today) => {
        // if obj is empty, do nothing
        if (Object.keys(obj).length === 0) {
          return s
        }
        obj = Object.assign({}, defaults$1, today, obj);
        let keys = Object.keys(obj);
        for (let i = 0; i < keys.length; i++) {
          let unit = keys[i];
          //make sure we have this method
          if (s[unit] === undefined || typeof s[unit] !== 'function') {
            continue
          }
          //make sure the value is a number
          if (obj[unit] === null || obj[unit] === undefined || obj[unit] === '') {
            continue
          }
          let num = obj[unit] || today[unit] || defaults$1[unit] || 0;
          s = s[unit](num);
        }
        return s
      };
    
      // this may seem like an arbitrary number, but it's 'within jan 1970'
      // this is only really ambiguous until 2054 or so
      const parseNumber$1 = function (s, input) {
        const minimumEpoch = 2500000000;
        // if the given epoch is really small, they've probably given seconds and not milliseconds
        // anything below this number is likely (but not necessarily) a mistaken input.
        if (input > 0 && input < minimumEpoch && s.silent === false) {
          console.warn('  - Warning: You are setting the date to January 1970.');
          console.warn('       -   did input seconds instead of milliseconds?');
        }
        s.epoch = input;
        return s
      };
    
      var fns = {
        parseArray: parseArray$1,
        parseObject: parseObject$1,
        parseNumber: parseNumber$1
      };
    
      // pull in 'today' data for the baseline moment
      const getNow = function (s) {
        s.epoch = Date.now();
        Object.keys(s._today || {}).forEach((k) => {
          if (typeof s[k] === 'function') {
            s = s[k](s._today[k]);
          }
        });
        return s
      };
    
      const dates = {
        now: (s) => {
          return getNow(s)
        },
        today: (s) => {
          return getNow(s)
        },
        tonight: (s) => {
          s = getNow(s);
          s = s.hour(18); //6pm
          return s
        },
        tomorrow: (s) => {
          s = getNow(s);
          s = s.add(1, 'day');
          s = s.startOf('day');
          return s
        },
        yesterday: (s) => {
          s = getNow(s);
          s = s.subtract(1, 'day');
          s = s.startOf('day');
          return s
        },
        christmas: (s) => {
          let year = getNow(s).year();
          s = s.set([year, 11, 25, 18, 0, 0]); // Dec 25
          return s
        },
        'new years': (s) => {
          let year = getNow(s).year();
          s = s.set([year, 11, 31, 18, 0, 0]); // Dec 31
          return s
        }
      };
      dates['new years eve'] = dates['new years'];
      var namedDates = dates;
    
      //little cleanup..
      const normalize = function (str) {
        // remove all day-names
        str = str.replace(/\b(mon|tues?|wed|wednes|thur?s?|fri|sat|satur|sun)(day)?\b/i, '');
        //remove ordinal ending
        str = str.replace(/([0-9])(th|rd|st|nd)/, '$1');
        str = str.replace(/,/g, '');
        str = str.replace(/ +/g, ' ').trim();
        return str
      };
    
      var normalize$1 = normalize;
    
      let o = {
        millisecond: 1
      };
      o.second = 1000;
      o.minute = 60000;
      o.hour = 3.6e6; // dst is supported post-hoc
      o.day = 8.64e7; //
      o.date = o.day;
      o.month = 8.64e7 * 29.5; //(average)
      o.week = 6.048e8;
      o.year = 3.154e10; // leap-years are supported post-hoc
      //add plurals
      Object.keys(o).forEach(k => {
        o[k + 's'] = o[k];
      });
      var ms = o;
    
      //basically, step-forward/backward until js Date object says we're there.
      const walk = (s, n, fn, unit, previous) => {
        let current = s.d[fn]();
        if (current === n) {
          return //already there
        }
        let startUnit = previous === null ? null : s.d[previous]();
        let original = s.epoch;
        //try to get it as close as we can
        let diff = n - current;
        s.epoch += ms[unit] * diff;
        //DST edge-case: if we are going many days, be a little conservative
        // console.log(unit, diff)
        if (unit === 'day') {
          // s.epoch -= ms.minute
          //but don't push it over a month
          if (Math.abs(diff) > 28 && n < 28) {
            s.epoch += ms.hour;
          }
        }
        // 1st time: oops, did we change previous unit? revert it.
        if (previous !== null && startUnit !== s.d[previous]()) {
          // console.warn('spacetime warning: missed setting ' + unit)
          s.epoch = original;
          // s.epoch += ms[unit] * diff * 0.89 // maybe try and make it close...?
        }
        //repair it if we've gone too far or something
        //(go by half-steps, just in case)
        const halfStep = ms[unit] / 2;
        while (s.d[fn]() < n) {
          s.epoch += halfStep;
        }
    
        while (s.d[fn]() > n) {
          s.epoch -= halfStep;
        }
        // 2nd time: did we change previous unit? revert it.
        if (previous !== null && startUnit !== s.d[previous]()) {
          // console.warn('spacetime warning: missed setting ' + unit)
          s.epoch = original;
        }
      };
      //find the desired date by a increment/check while loop
      const units$3 = {
        year: {
          valid: (n) => n > -4000 && n < 4000,
          walkTo: (s, n) => walk(s, n, 'getFullYear', 'year', null)
        },
        month: {
          valid: (n) => n >= 0 && n <= 11,
          walkTo: (s, n) => {
            let d = s.d;
            let current = d.getMonth();
            let original = s.epoch;
            let startUnit = d.getFullYear();
            if (current === n) {
              return
            }
            //try to get it as close as we can..
            let diff = n - current;
            s.epoch += ms.day * (diff * 28); //special case
            //oops, did we change the year? revert it.
            if (startUnit !== s.d.getFullYear()) {
              s.epoch = original;
            }
            //increment by day
            while (s.d.getMonth() < n) {
              s.epoch += ms.day;
            }
            while (s.d.getMonth() > n) {
              s.epoch -= ms.day;
            }
          }
        },
        date: {
          valid: (n) => n > 0 && n <= 31,
          walkTo: (s, n) => walk(s, n, 'getDate', 'day', 'getMonth')
        },
        hour: {
          valid: (n) => n >= 0 && n < 24,
          walkTo: (s, n) => walk(s, n, 'getHours', 'hour', 'getDate')
        },
        minute: {
          valid: (n) => n >= 0 && n < 60,
          walkTo: (s, n) => walk(s, n, 'getMinutes', 'minute', 'getHours')
        },
        second: {
          valid: (n) => n >= 0 && n < 60,
          walkTo: (s, n) => {
            //do this one directly
            s.epoch = s.seconds(n).epoch;
          }
        },
        millisecond: {
          valid: (n) => n >= 0 && n < 1000,
          walkTo: (s, n) => {
            //do this one directly
            s.epoch = s.milliseconds(n).epoch;
          }
        }
      };
    
      const walkTo = (s, wants) => {
        let keys = Object.keys(units$3);
        let old = s.clone();
        for (let i = 0; i < keys.length; i++) {
          let k = keys[i];
          let n = wants[k];
          if (n === undefined) {
            n = old[k]();
          }
          if (typeof n === 'string') {
            n = parseInt(n, 10);
          }
          //make-sure it's valid
          if (!units$3[k].valid(n)) {
            s.epoch = null;
            if (s.silent === false) {
              console.warn('invalid ' + k + ': ' + n);
            }
            return
          }
          units$3[k].walkTo(s, n);
        }
        return
      };
    
      var walkTo$1 = walkTo;
    
      const monthLengths = [
        31, // January - 31 days
        28, // February - 28 days in a common year and 29 days in leap years
        31, // March - 31 days
        30, // April - 30 days
        31, // May - 31 days
        30, // June - 30 days
        31, // July - 31 days
        31, // August - 31 days
        30, // September - 30 days
        31, // October - 31 days
        30, // November - 30 days
        31 // December - 31 days
      ];
      var monthLength = monthLengths;
    
      // 28 - feb
      // 30 - april, june, sept, nov
      // 31 - jan, march, may, july, aug, oct, dec
    
      let shortMonths = [
        'jan',
        'feb',
        'mar',
        'apr',
        'may',
        'jun',
        'jul',
        'aug',
        'sep',
        'oct',
        'nov',
        'dec'
      ];
      let longMonths = [
        'january',
        'february',
        'march',
        'april',
        'may',
        'june',
        'july',
        'august',
        'september',
        'october',
        'november',
        'december'
      ];
    
      function buildMapping() {
        const obj = {
          sep: 8 //support this format
        };
        for (let i = 0; i < shortMonths.length; i++) {
          obj[shortMonths[i]] = i;
        }
        for (let i = 0; i < longMonths.length; i++) {
          obj[longMonths[i]] = i;
        }
        return obj
      }
    
      function short$1() { return shortMonths }
      function long$1() { return longMonths }
      function mapping$1() { return buildMapping() }
      function set$2(i18n) {
        shortMonths = i18n.short || shortMonths;
        longMonths = i18n.long || longMonths;
      }
    
      //pull-apart ISO offsets, like "+0100"
      const parseOffset = (s, offset) => {
        if (!offset) {
          return s
        }
    
        // according to ISO8601, tz could be hh:mm, hhmm or hh
        // so need few more steps before the calculation.
        let num = 0;
    
        // for (+-)hh:mm
        if (/^[\+-]?[0-9]{2}:[0-9]{2}$/.test(offset)) {
          //support "+01:00"
          if (/:00/.test(offset) === true) {
            offset = offset.replace(/:00/, '');
          }
          //support "+01:30"
          if (/:30/.test(offset) === true) {
            offset = offset.replace(/:30/, '.5');
          }
        }
    
        // for (+-)hhmm
        if (/^[\+-]?[0-9]{4}$/.test(offset)) {
          offset = offset.replace(/30$/, '.5');
        }
        num = parseFloat(offset);
    
        //divide by 100 or 10 - , "+0100", "+01"
        if (Math.abs(num) > 100) {
          num = num / 100;
        }
        //this is a fancy-move
        if (num === 0 || offset === 'Z' || offset === 'z') {
          s.tz = 'etc/gmt';
          return s
        }
        //okay, try to match it to a utc timezone
        //remember - this is opposite! a -5 offset maps to Etc/GMT+5  ¯\_(:/)_/¯
        //https://askubuntu.com/questions/519550/why-is-the-8-timezone-called-gmt-8-in-the-filesystem
        num *= -1;
    
        if (num >= 0) {
          num = '+' + num;
        }
        let tz = 'etc/gmt' + num;
        let zones = s.timezones;
    
        if (zones[tz]) {
          // log a warning if we're over-writing a given timezone?
          // console.log('changing timezone to: ' + tz)
          s.tz = tz;
        }
        return s
      };
      var parseOffset$1 = parseOffset;
    
      // truncate any sub-millisecond values
      const parseMs = function (str = '') {
        str = String(str);
        //js does not support sub-millisecond values 
        // so truncate these - 2021-11-02T19:55:30.087772
        if (str.length > 3) {
          str = str.substr(0, 3);
        } else if (str.length === 1) {
          // assume ms are zero-padded on the left
          // but maybe not on the right.
          // turn '.10' into '.100'
          str = str + '00';
        } else if (str.length === 2) {
          str = str + '0';
        }
        return Number(str) || 0
      };
    
      const parseTime = (s, str = '') => {
        // remove all whitespace
        str = str.replace(/^\s+/, '').toLowerCase();
        //formal time format - 04:30.23
        let arr = str.match(/([0-9]{1,2}):([0-9]{1,2}):?([0-9]{1,2})?[:\.]?([0-9]{1,4})?/);
        if (arr !== null) {
          //validate it a little
          let h = Number(arr[1]);
          if (h < 0 || h > 24) {
            return s.startOf('day')
          }
          let m = Number(arr[2]); //don't accept '5:3pm'
          if (arr[2].length < 2 || m < 0 || m > 59) {
            return s.startOf('day')
          }
          s = s.hour(h);
          s = s.minute(m);
          s = s.seconds(arr[3] || 0);
          s = s.millisecond(parseMs(arr[4]));
          //parse-out am/pm
          let ampm = str.match(/[\b0-9] ?(am|pm)\b/);
          if (ampm !== null && ampm[1]) {
            s = s.ampm(ampm[1]);
          }
          return s
        }
    
        //try an informal form - 5pm (no minutes)
        arr = str.match(/([0-9]+) ?(am|pm)/);
        if (arr !== null && arr[1]) {
          let h = Number(arr[1]);
          //validate it a little..
          if (h > 12 || h < 1) {
            return s.startOf('day')
          }
          s = s.hour(arr[1] || 0);
          s = s.ampm(arr[2]);
          s = s.startOf('hour');
          return s
        }
    
        //no time info found, use start-of-day
        s = s.startOf('day');
        return s
      };
      var parseTime$1 = parseTime;
    
      let months$1 = mapping$1();
    
      //given a month, return whether day number exists in it
      const validate$1 = (obj) => {
        //invalid values
        if (monthLength.hasOwnProperty(obj.month) !== true) {
          return false
        }
        //support leap-year in february
        if (obj.month === 1) {
          if (isLeapYear(obj.year) && obj.date <= 29) {
            return true
          } else {
            return obj.date <= 28
          }
        }
        //is this date too-big for this month?
        let max = monthLength[obj.month] || 0;
        if (obj.date <= max) {
          return true
        }
        return false
      };
    
      const parseYear = (str = '', today) => {
        str = str.trim();
        // parse '86 shorthand
        if (/^'[0-9][0-9]$/.test(str) === true) {
          let num = Number(str.replace(/'/, ''));
          if (num > 50) {
            return 1900 + num
          }
          return 2000 + num
        }
        let year = parseInt(str, 10);
        // use a given year from options.today
        if (!year && today) {
          year = today.year;
        }
        // fallback to this year
        year = year || new Date().getFullYear();
        return year
      };
    
      const parseMonth = function (str) {
        str = str.toLowerCase().trim();
        if (str === 'sept') {
          return months$1.sep
        }
        return months$1[str]
      };
    
      var ymd = [
        // =====
        //  y-m-d
        // =====
        //iso-this 1998-05-30T22:00:00:000Z, iso-that 2017-04-03T08:00:00-0700
        {
          reg: /^(\-?0?0?[0-9]{3,4})-([0-9]{1,2})-([0-9]{1,2})[T| ]([0-9.:]+)(Z|[0-9\-\+:]+)?$/i,
          parse: (s, m) => {
            let obj = {
              year: m[1],
              month: parseInt(m[2], 10) - 1,
              date: m[3]
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            parseOffset$1(s, m[5]);
            walkTo$1(s, obj);
            s = parseTime$1(s, m[4]);
            return s
          }
        },
        //short-iso "2015-03-25" or "2015/03/25" or "2015/03/25 12:26:14 PM"
        {
          reg: /^([0-9]{4})[\-\/\. ]([0-9]{1,2})[\-\/\. ]([0-9]{1,2})( [0-9]{1,2}(:[0-9]{0,2})?(:[0-9]{0,3})? ?(am|pm)?)?$/i,
          parse: (s, m) => {
            let obj = {
              year: m[1],
              month: parseInt(m[2], 10) - 1,
              date: parseInt(m[3], 10)
            };
            if (obj.month >= 12) {
              //support yyyy/dd/mm (weird, but ok)
              obj.date = parseInt(m[2], 10);
              obj.month = parseInt(m[3], 10) - 1;
            }
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, m[4]);
            return s
          }
        },
    
        //text-month "2015-feb-25"
        {
          reg: /^([0-9]{4})[\-\/\. ]([a-z]+)[\-\/\. ]([0-9]{1,2})( [0-9]{1,2}(:[0-9]{0,2})?(:[0-9]{0,3})? ?(am|pm)?)?$/i,
          parse: (s, m) => {
            let obj = {
              year: parseYear(m[1], s._today),
              month: parseMonth(m[2]),
              date: toCardinal(m[3] || '')
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, m[4]);
            return s
          }
        }
      ];
    
      var mdy = [
        // =====
        //  m-d-y
        // =====
        //mm/dd/yyyy - uk/canada "6/28/2019, 12:26:14 PM"
        {
          reg: /^([0-9]{1,2})[\-\/.]([0-9]{1,2})[\-\/.]?([0-9]{4})?( [0-9]{1,2}:[0-9]{2}:?[0-9]{0,2}? ?(am|pm|gmt))?$/i,
          parse: (s, arr) => {
            let month = parseInt(arr[1], 10) - 1;
            let date = parseInt(arr[2], 10);
            //support dd/mm/yyy
            if (s.british || month >= 12) {
              date = parseInt(arr[1], 10);
              month = parseInt(arr[2], 10) - 1;
            }
            let obj = {
              date,
              month,
              year: parseYear(arr[3], s._today) || new Date().getFullYear()
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, arr[4]);
            return s
          }
        },
        //alt short format - "feb-25-2015"
        {
          reg: /^([a-z]+)[\-\/\. ]([0-9]{1,2})[\-\/\. ]?([0-9]{4}|'[0-9]{2})?( [0-9]{1,2}(:[0-9]{0,2})?(:[0-9]{0,3})? ?(am|pm)?)?$/i,
          parse: (s, arr) => {
            let obj = {
              year: parseYear(arr[3], s._today),
              month: parseMonth(arr[1]),
              date: toCardinal(arr[2] || '')
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, arr[4]);
            return s
          }
        },
    
        //Long "Mar 25 2015"
        //February 22, 2017 15:30:00
        {
          reg: /^([a-z]+) ([0-9]{1,2})( [0-9]{4})?( ([0-9:]+( ?am| ?pm| ?gmt)?))?$/i,
          parse: (s, arr) => {
            let obj = {
              year: parseYear(arr[3], s._today),
              month: parseMonth(arr[1]),
              date: toCardinal(arr[2] || '')
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, arr[4]);
            return s
          }
        },
        // 'Sun Mar 14 15:09:48 +0000 2021'
        {
          reg: /^([a-z]+) ([0-9]{1,2})( [0-9:]+)?( \+[0-9]{4})?( [0-9]{4})?$/i,
          parse: (s, arr) => {
            let obj = {
              year: parseYear(arr[5], s._today),
              month: parseMonth(arr[1]),
              date: toCardinal(arr[2] || '')
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, arr[3]);
            return s
          }
        }
      ];
    
      var dmy = [
        // =====
        //  d-m-y
        // =====
        //common british format - "25-feb-2015"
        {
          reg: /^([0-9]{1,2})[\-\/]([a-z]+)[\-\/]?([0-9]{4})?$/i,
          parse: (s, m) => {
            let obj = {
              year: parseYear(m[3], s._today),
              month: parseMonth(m[2]),
              date: toCardinal(m[1] || '')
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, m[4]);
            return s
          }
        },
        // "25 Mar 2015"
        {
          reg: /^([0-9]{1,2})( [a-z]+)( [0-9]{4}| '[0-9]{2})? ?([0-9]{1,2}:[0-9]{2}:?[0-9]{0,2}? ?(am|pm|gmt))?$/i,
          parse: (s, m) => {
            let obj = {
              year: parseYear(m[3], s._today),
              month: parseMonth(m[2]),
              date: toCardinal(m[1])
            };
            if (!obj.month || validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, m[4]);
            return s
          }
        },
        // 01-jan-2020
        {
          reg: /^([0-9]{1,2})[\. -/]([a-z]+)[\. -/]([0-9]{4})?( [0-9]{1,2}(:[0-9]{0,2})?(:[0-9]{0,3})? ?(am|pm)?)?$/i,
          parse: (s, m) => {
            let obj = {
              date: Number(m[1]),
              month: parseMonth(m[2]),
              year: Number(m[3])
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = s.startOf('day');
            s = parseTime$1(s, m[4]);
            return s
          }
        }
      ];
    
      var misc = [
        // =====
        // no dates
        // =====
    
        // '2012-06' month-only
        {
          reg: /^([0-9]{4})[\-\/]([0-9]{2})$/i,
          parse: (s, m) => {
            let obj = {
              year: m[1],
              month: parseInt(m[2], 10) - 1,
              date: 1
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, m[4]);
            return s
          }
        },
    
        //February 2017 (implied date)
        {
          reg: /^([a-z]+) ([0-9]{4})$/i,
          parse: (s, arr) => {
            let obj = {
              year: parseYear(arr[2], s._today),
              month: parseMonth(arr[1]),
              date: s._today.date || 1
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s, arr[4]);
            return s
          }
        },
    
        {
          // 'q2 2002'
          reg: /^(q[0-9])( of)?( [0-9]{4})?/i,
          parse: (s, arr) => {
            let quarter = arr[1] || '';
            s = s.quarter(quarter);
            let year = arr[3] || '';
            if (year) {
              year = year.trim();
              s = s.year(year);
            }
            return s
          }
        },
        {
          // 'summer 2002'
          reg: /^(spring|summer|winter|fall|autumn)( of)?( [0-9]{4})?/i,
          parse: (s, arr) => {
            let season = arr[1] || '';
            s = s.season(season);
            let year = arr[3] || '';
            if (year) {
              year = year.trim();
              s = s.year(year);
            }
            return s
          }
        },
        {
          // '200bc'
          reg: /^[0-9,]+ ?b\.?c\.?$/i,
          parse: (s, arr) => {
            let str = arr[0] || '';
            //make year-negative
            str = str.replace(/^([0-9,]+) ?b\.?c\.?$/i, '-$1');
            let d = new Date();
            let obj = {
              year: parseInt(str.trim(), 10),
              month: d.getMonth(),
              date: d.getDate()
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s);
            return s
          }
        },
        {
          // '200ad'
          reg: /^[0-9,]+ ?(a\.?d\.?|c\.?e\.?)$/i,
          parse: (s, arr) => {
            let str = arr[0] || '';
            //remove commas
            str = str.replace(/,/g, '');
            let d = new Date();
            let obj = {
              year: parseInt(str.trim(), 10),
              month: d.getMonth(),
              date: d.getDate()
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s);
            return s
          }
        },
        {
          // '1992'
          reg: /^[0-9]{4}( ?a\.?d\.?)?$/i,
          parse: (s, arr) => {
            let today = s._today;
            // using today's date, but a new month is awkward.
            if (today.month && !today.date) {
              today.date = 1;
            }
            let d = new Date();
            let obj = {
              year: parseYear(arr[0], today),
              month: today.month || d.getMonth(),
              date: today.date || d.getDate()
            };
            if (validate$1(obj) === false) {
              s.epoch = null;
              return s
            }
            walkTo$1(s, obj);
            s = parseTime$1(s);
            return s
          }
        }
      ];
    
      var parsers = [].concat(ymd, mdy, dmy, misc);
    
      const parseString = function (s, input, givenTz) {
        // let parsers = s.parsers || []
        //try each text-parse template, use the first good result
        for (let i = 0; i < parsers.length; i++) {
          let m = input.match(parsers[i].reg);
          if (m) {
            // console.log(parsers[i].reg)
            let res = parsers[i].parse(s, m, givenTz);
            if (res !== null && res.isValid()) {
              return res
            }
          }
        }
        if (s.silent === false) {
          console.warn("Warning: couldn't parse date-string: '" + input + "'");
        }
        s.epoch = null;
        return s
      };
      var parseString$1 = parseString;
    
      const { parseArray, parseObject, parseNumber } = fns;
      //we have to actually parse these inputs ourselves
      //  -  can't use built-in js parser ;(
      //=========================================
      // ISO Date	  "2015-03-25"
      // Short Date	"03/25/2015" or "2015/03/25"
      // Long Date	"Mar 25 2015" or "25 Mar 2015"
      // Full Date	"Wednesday March 25 2015"
      //=========================================
    
      const defaults = {
        year: new Date().getFullYear(),
        month: 0,
        date: 1
      };
    
      //find the epoch from different input styles
      const parseInput = (s, input) => {
        let today = s._today || defaults;
        //if we've been given a epoch number, it's easy
        if (typeof input === 'number') {
          return parseNumber(s, input)
        }
        //set tmp time
        s.epoch = Date.now();
        // overwrite tmp time with 'today' value, if exists
        if (s._today && isObject(s._today) && Object.keys(s._today).length > 0) {
          let res = parseObject(s, today, defaults);
          if (res.isValid()) {
            s.epoch = res.epoch;
          }
        }
        // null input means 'now'
        if (input === null || input === undefined || input === '') {
          return s //k, we're good.
        }
        //support input of Date() object
        if (isDate(input) === true) {
          s.epoch = input.getTime();
          return s
        }
        //support [2016, 03, 01] format
        if (isArray(input) === true) {
          s = parseArray(s, input, today);
          return s
        }
        //support {year:2016, month:3} format
        if (isObject(input) === true) {
          //support spacetime object as input
          if (input.epoch) {
            s.epoch = input.epoch;
            s.tz = input.tz;
            return s
          }
          s = parseObject(s, input, today);
          return s
        }
        //input as a string..
        if (typeof input !== 'string') {
          return s
        }
        //little cleanup..
        input = normalize$1(input);
        //try some known-words, like 'now'
        if (namedDates.hasOwnProperty(input) === true) {
          s = namedDates[input](s);
          return s
        }
        //try each text-parse template, use the first good result
        return parseString$1(s, input)
      };
      var handleInput = parseInput;
    
      let shortDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
      let longDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    
      function short() { return shortDays }
      function long() { return longDays }
      function set$1(i18n) {
        shortDays = i18n.short || shortDays;
        longDays = i18n.long || longDays;
      }
      const aliases$1 = {
        mo: 1,
        tu: 2,
        we: 3,
        th: 4,
        fr: 5,
        sa: 6,
        su: 7,
        tues: 2,
        weds: 3,
        wedn: 3,
        thur: 4,
        thurs: 4
      };
    
      let titleCaseEnabled = true;
    
      function useTitleCase() {
        return titleCaseEnabled
      }
    
      function set(val) {
        titleCaseEnabled = val;
      }
    
      // create the timezone offset part of an iso timestamp
      // it's kind of nuts how involved this is
      // "+01:00", "+0100", or simply "+01"
      const isoOffset = s => {
        let offset = s.timezone().current.offset;
        return !offset ? 'Z' : formatTimezone(offset, ':')
      };
    
      var isoOffset$1 = isoOffset;
    
      const applyCaseFormat = (str) => {
        if (useTitleCase()) {
          return titleCase$1(str)
        }
        return str
      };
    
      // iso-year padding
      const padYear = (num) => {
        if (num >= 0) {
          return zeroPad(num, 4)
        } else {
          num = Math.abs(num);
          return '-' + zeroPad(num, 4)
        }
      };
    
      const format = {
        day: (s) => applyCaseFormat(s.dayName()),
        'day-short': (s) => applyCaseFormat(short()[s.day()]),
        'day-number': (s) => s.day(),
        'day-ordinal': (s) => ordinal(s.day()),
        'day-pad': (s) => zeroPad(s.day()),
    
        date: (s) => s.date(),
        'date-ordinal': (s) => ordinal(s.date()),
        'date-pad': (s) => zeroPad(s.date()),
    
        month: (s) => applyCaseFormat(s.monthName()),
        'month-short': (s) => applyCaseFormat(short$1()[s.month()]),
        'month-number': (s) => s.month(),
        'month-ordinal': (s) => ordinal(s.month()),
        'month-pad': (s) => zeroPad(s.month()),
        'iso-month': (s) => zeroPad(s.month() + 1), //1-based months
    
        year: (s) => {
          let year = s.year();
          if (year > 0) {
            return year
          }
          year = Math.abs(year);
          return year + ' BC'
        },
        'year-short': (s) => {
          let year = s.year();
          if (year > 0) {
            return `'${String(s.year()).substr(2, 4)}`
          }
          year = Math.abs(year);
          return year + ' BC'
        },
        'iso-year': (s) => {
          let year = s.year();
          let isNegative = year < 0;
          let str = zeroPad(Math.abs(year), 4); //0-padded
          if (isNegative) {
            //negative years are for some reason 6-digits ('-00008')
            str = zeroPad(str, 6);
            str = '-' + str;
          }
          return str
        },
    
        time: (s) => s.time(),
        'time-24': (s) => `${s.hour24()}:${zeroPad(s.minute())}`,
    
        hour: (s) => s.hour12(),
        'hour-pad': (s) => zeroPad(s.hour12()),
        'hour-24': (s) => s.hour24(),
        'hour-24-pad': (s) => zeroPad(s.hour24()),
    
        minute: (s) => s.minute(),
        'minute-pad': (s) => zeroPad(s.minute()),
        second: (s) => s.second(),
        'second-pad': (s) => zeroPad(s.second()),
        millisecond: (s) => s.millisecond(),
        'millisecond-pad': (s) => zeroPad(s.millisecond(), 3),
    
        ampm: (s) => s.ampm(),
        quarter: (s) => 'Q' + s.quarter(),
        season: (s) => s.season(),
        era: (s) => s.era(),
        json: (s) => s.json(),
        timezone: (s) => s.timezone().name,
        offset: (s) => isoOffset$1(s),
    
        numeric: (s) => `${s.year()}/${zeroPad(s.month() + 1)}/${zeroPad(s.date())}`, // yyyy/mm/dd
        'numeric-us': (s) => `${zeroPad(s.month() + 1)}/${zeroPad(s.date())}/${s.year()}`, // mm/dd/yyyy
        'numeric-uk': (s) => `${zeroPad(s.date())}/${zeroPad(s.month() + 1)}/${s.year()}`, //dd/mm/yyyy
        'mm/dd': (s) => `${zeroPad(s.month() + 1)}/${zeroPad(s.date())}`, //mm/dd
    
        // ... https://en.wikipedia.org/wiki/ISO_8601 ;(((
        iso: (s) => {
          let year = s.format('iso-year');
          let month = zeroPad(s.month() + 1); //1-based months
          let date = zeroPad(s.date());
          let hour = zeroPad(s.h24());
          let minute = zeroPad(s.minute());
          let second = zeroPad(s.second());
          let ms = zeroPad(s.millisecond(), 3);
          let offset = isoOffset$1(s);
          return `${year}-${month}-${date}T${hour}:${minute}:${second}.${ms}${offset}` //2018-03-09T08:50:00.000-05:00
        },
        'iso-short': (s) => {
          let month = zeroPad(s.month() + 1); //1-based months
          let date = zeroPad(s.date());
          let year = padYear(s.year());
          return `${year}-${month}-${date}` //2017-02-15
        },
        'iso-utc': (s) => {
          return new Date(s.epoch).toISOString() //2017-03-08T19:45:28.367Z
        },
    
        //i made these up
        nice: (s) => `${short$1()[s.month()]} ${ordinal(s.date())}, ${s.time()}`,
        'nice-24': (s) =>
          `${short$1()[s.month()]} ${ordinal(s.date())}, ${s.hour24()}:${zeroPad(
          s.minute()
        )}`,
        'nice-year': (s) => `${short$1()[s.month()]} ${ordinal(s.date())}, ${s.year()}`,
        'nice-day': (s) =>
          `${short()[s.day()]} ${applyCaseFormat(short$1()[s.month()])} ${ordinal(
          s.date()
        )}`,
        'nice-full': (s) =>
          `${s.dayName()} ${applyCaseFormat(s.monthName())} ${ordinal(s.date())}, ${s.time()}`,
        'nice-full-24': (s) =>
          `${s.dayName()} ${applyCaseFormat(s.monthName())} ${ordinal(
          s.date()
        )}, ${s.hour24()}:${zeroPad(s.minute())}`
      };
      //aliases
      const aliases = {
        'day-name': 'day',
        'month-name': 'month',
        'iso 8601': 'iso',
        'time-h24': 'time-24',
        'time-12': 'time',
        'time-h12': 'time',
        tz: 'timezone',
        'day-num': 'day-number',
        'month-num': 'month-number',
        'month-iso': 'iso-month',
        'year-iso': 'iso-year',
        'nice-short': 'nice',
        'nice-short-24': 'nice-24',
        mdy: 'numeric-us',
        dmy: 'numeric-uk',
        ymd: 'numeric',
        'yyyy/mm/dd': 'numeric',
        'mm/dd/yyyy': 'numeric-us',
        'dd/mm/yyyy': 'numeric-us',
        'little-endian': 'numeric-uk',
        'big-endian': 'numeric',
        'day-nice': 'nice-day'
      };
      Object.keys(aliases).forEach((k) => (format[k] = format[aliases[k]]));
    
      const printFormat = (s, str = '') => {
        //don't print anything if it's an invalid date
        if (s.isValid() !== true) {
          return ''
        }
        //support .format('month')
        if (format.hasOwnProperty(str)) {
          let out = format[str](s) || '';
          if (str !== 'json') {
            out = String(out);
            if (str !== 'ampm') {
              out = applyCaseFormat(out);
            }
          }
          return out
        }
        //support '{hour}:{minute}' notation
        if (str.indexOf('{') !== -1) {
          let sections = /\{(.+?)\}/g;
          str = str.replace(sections, (_, fmt) => {
            fmt = fmt.toLowerCase().trim();
            if (format.hasOwnProperty(fmt)) {
              let out = String(format[fmt](s));
              if (fmt !== 'ampm') {
                return applyCaseFormat(out)
              }
              return out
            }
            return ''
          });
          return str
        }
    
        return s.format('iso-short')
      };
      var format$1 = printFormat;
    
      //parse this insane unix-time-templating thing, from the 19th century
      //http://unicode.org/reports/tr35/tr35-25.html#Date_Format_Patterns
    
      //time-symbols we support
      const mapping = {
        G: (s) => s.era(),
        GG: (s) => s.era(),
        GGG: (s) => s.era(),
        GGGG: (s) => (s.era() === 'AD' ? 'Anno Domini' : 'Before Christ'),
        //year
        y: (s) => s.year(),
        yy: (s) => {
          //last two chars
          return zeroPad(Number(String(s.year()).substr(2, 4)))
        },
        yyy: (s) => s.year(),
        yyyy: (s) => s.year(),
        yyyyy: (s) => '0' + s.year(),
        // u: (s) => {},//extended non-gregorian years
    
        //quarter
        Q: (s) => s.quarter(),
        QQ: (s) => s.quarter(),
        QQQ: (s) => s.quarter(),
        QQQQ: (s) => s.quarter(),
    
        //month
        M: (s) => s.month() + 1,
        MM: (s) => zeroPad(s.month() + 1),
        MMM: (s) => s.format('month-short'),
        MMMM: (s) => s.format('month'),
    
        //week
        w: (s) => s.week(),
        ww: (s) => zeroPad(s.week()),
        //week of month
        // W: (s) => s.week(),
    
        //date of month
        d: (s) => s.date(),
        dd: (s) => zeroPad(s.date()),
        //date of year
        D: (s) => s.dayOfYear(),
        DD: (s) => zeroPad(s.dayOfYear()),
        DDD: (s) => zeroPad(s.dayOfYear(), 3),
    
        // F: (s) => {},//date of week in month
        // g: (s) => {},//modified julian day
    
        //day
        E: (s) => s.format('day-short'),
        EE: (s) => s.format('day-short'),
        EEE: (s) => s.format('day-short'),
        EEEE: (s) => s.format('day'),
        EEEEE: (s) => s.format('day')[0],
        e: (s) => s.day(),
        ee: (s) => s.day(),
        eee: (s) => s.format('day-short'),
        eeee: (s) => s.format('day'),
        eeeee: (s) => s.format('day')[0],
    
        //am/pm
        a: (s) => s.ampm().toUpperCase(),
        aa: (s) => s.ampm().toUpperCase(),
        aaa: (s) => s.ampm().toUpperCase(),
        aaaa: (s) => s.ampm().toUpperCase(),
    
        //hour
        h: (s) => s.h12(),
        hh: (s) => zeroPad(s.h12()),
        H: (s) => s.hour(),
        HH: (s) => zeroPad(s.hour()),
        // j: (s) => {},//weird hour format
    
        m: (s) => s.minute(),
        mm: (s) => zeroPad(s.minute()),
        s: (s) => s.second(),
        ss: (s) => zeroPad(s.second()),
    
        //milliseconds
        SSS: (s) => zeroPad(s.millisecond(), 3),
        //milliseconds in the day
        A: (s) => s.epoch - s.startOf('day').epoch,
        //timezone
        z: (s) => s.timezone().name,
        zz: (s) => s.timezone().name,
        zzz: (s) => s.timezone().name,
        zzzz: (s) => s.timezone().name,
        Z: (s) => formatTimezone(s.timezone().current.offset),
        ZZ: (s) => formatTimezone(s.timezone().current.offset),
        ZZZ: (s) => formatTimezone(s.timezone().current.offset),
        ZZZZ: (s) => formatTimezone(s.timezone().current.offset, ':')
      };
    
      const addAlias = (char, to, n) => {
        let name = char;
        let toName = to;
        for (let i = 0; i < n; i += 1) {
          mapping[name] = mapping[toName];
          name += char;
          toName += to;
        }
      };
      addAlias('q', 'Q', 4);
      addAlias('L', 'M', 4);
      addAlias('Y', 'y', 4);
      addAlias('c', 'e', 4);
      addAlias('k', 'H', 2);
      addAlias('K', 'h', 2);
      addAlias('S', 's', 2);
      addAlias('v', 'z', 4);
      addAlias('V', 'Z', 4);
    
      // support unix-style escaping with ' character
      const escapeChars = function (arr) {
        for (let i = 0; i < arr.length; i += 1) {
          if (arr[i] === `'`) {
            // greedy-search for next apostrophe
            for (let o = i + 1; o < arr.length; o += 1) {
              if (arr[o]) {
                arr[i] += arr[o];
              }
              if (arr[o] === `'`) {
                arr[o] = null;
                break
              }
              arr[o] = null;
            }
          }
        }
        return arr.filter((ch) => ch)
      };
    
      //combine consecutive chars, like 'yyyy' as one.
      const combineRepeated = function (arr) {
        for (let i = 0; i < arr.length; i += 1) {
          let c = arr[i];
          // greedy-forward
          for (let o = i + 1; o < arr.length; o += 1) {
            if (arr[o] === c) {
              arr[i] += arr[o];
              arr[o] = null;
            } else {
              break
            }
          }
        }
        // '' means one apostrophe
        arr = arr.filter((ch) => ch);
        arr = arr.map((str) => {
          if (str === `''`) {
            str = `'`;
          }
          return str
        });
        return arr
      };
    
      const unixFmt = (s, str) => {
        let arr = str.split('');
        // support character escaping
        arr = escapeChars(arr);
        //combine 'yyyy' as string.
        arr = combineRepeated(arr);
        return arr.reduce((txt, c) => {
          if (mapping[c] !== undefined) {
            txt += mapping[c](s) || '';
          } else {
            // 'unescape'
            if (/^'.{1,}'$/.test(c)) {
              c = c.replace(/'/g, '');
            }
            txt += c;
          }
          return txt
        }, '')
      };
      var unixFmt$1 = unixFmt;
    
      const units$2 = ['year', 'season', 'quarter', 'month', 'week', 'day', 'quarterHour', 'hour', 'minute'];
    
      const doUnit = function (s, k) {
        let start = s.clone().startOf(k);
        let end = s.clone().endOf(k);
        let duration = end.epoch - start.epoch;
        let percent = (s.epoch - start.epoch) / duration;
        return parseFloat(percent.toFixed(2))
      };
    
      //how far it is along, from 0-1
      const progress = (s, unit) => {
        if (unit) {
          unit = normalize$2(unit);
          return doUnit(s, unit)
        }
        let obj = {};
        units$2.forEach(k => {
          obj[k] = doUnit(s, k);
        });
        return obj
      };
    
      var progress$1 = progress;
    
      //round to either current, or +1 of this unit
      const nearest = (s, unit) => {
        //how far have we gone?
        let prog = s.progress();
        unit = normalize$2(unit);
        //fix camel-case for this one
        if (unit === 'quarterhour') {
          unit = 'quarterHour';
        }
        if (prog[unit] !== undefined) {
          // go forward one?
          if (prog[unit] > 0.5) {
            s = s.add(1, unit);
          }
          // go to start
          s = s.startOf(unit);
        } else if (s.silent === false) {
          console.warn("no known unit '" + unit + "'");
        }
        return s
      };
      var nearest$1 = nearest;
    
      //increment until dates are the same
      const climb = (a, b, unit) => {
        let i = 0;
        a = a.clone();
        while (a.isBefore(b)) {
          //do proper, expensive increment to catch all-the-tricks
          a = a.add(1, unit);
          i += 1;
        }
        //oops, we went too-far..
        if (a.isAfter(b, unit)) {
          i -= 1;
        }
        return i
      };
    
      // do a thurough +=1 on the unit, until they match
      // for speed-reasons, only used on day, month, week.
      const diffOne = (a, b, unit) => {
        if (a.isBefore(b)) {
          return climb(a, b, unit)
        } else {
          return climb(b, a, unit) * -1 //reverse it
        }
      };
    
      var diffOne$1 = diffOne;
    
      // don't do anything too fancy here.
      // 2020 - 2019 may be 1 year, or 0 years
      // - '1 year difference' means 366 days during a leap year
      const fastYear = (a, b) => {
        let years = b.year() - a.year();
        // should we decrement it by 1?
        a = a.year(b.year());
        if (a.isAfter(b)) {
          years -= 1;
        }
        return years
      };
    
      // use a waterfall-method for computing a diff of any 'pre-knowable' units
      // compute years, then compute months, etc..
      // ... then ms-math for any very-small units
      const diff$1 = function (a, b) {
        // an hour is always the same # of milliseconds
        // so these units can be 'pre-calculated'
        let msDiff = b.epoch - a.epoch;
        let obj = {
          milliseconds: msDiff,
          seconds: parseInt(msDiff / 1000, 10)
        };
        obj.minutes = parseInt(obj.seconds / 60, 10);
        obj.hours = parseInt(obj.minutes / 60, 10);
    
        //do the year
        let tmp = a.clone();
        obj.years = fastYear(tmp, b);
        tmp = a.add(obj.years, 'year');
    
        //there's always 12 months in a year...
        obj.months = obj.years * 12;
        tmp = a.add(obj.months, 'month');
        obj.months += diffOne$1(tmp, b, 'month');
    
        // there's always atleast 52 weeks in a year..
        // (month * 4) isn't as close
        obj.weeks = obj.years * 52;
        tmp = a.add(obj.weeks, 'week');
        obj.weeks += diffOne$1(tmp, b, 'week');
    
        // there's always atleast 7 days in a week
        obj.days = obj.weeks * 7;
        tmp = a.add(obj.days, 'day');
        obj.days += diffOne$1(tmp, b, 'day');
    
        return obj
      };
      var waterfall = diff$1;
    
      const reverseDiff = function (obj) {
        Object.keys(obj).forEach((k) => {
          obj[k] *= -1;
        });
        return obj
      };
    
      // this method counts a total # of each unit, between a, b.
      // '1 month' means 28 days in february
      // '1 year' means 366 days in a leap year
      const main$1 = function (a, b, unit) {
        b = beADate(b, a);
        //reverse values, if necessary
        let reversed = false;
        if (a.isAfter(b)) {
          let tmp = a;
          a = b;
          b = tmp;
          reversed = true;
        }
        //compute them all (i know!)
        let obj = waterfall(a, b);
        if (reversed) {
          obj = reverseDiff(obj);
        }
        //return just the requested unit
        if (unit) {
          //make sure it's plural-form
          unit = normalize$2(unit);
          if (/s$/.test(unit) !== true) {
            unit += 's';
          }
          if (unit === 'dates') {
            unit = 'days';
          }
          return obj[unit]
        }
        return obj
      };
    
      var diff = main$1;
    
      /*
      ISO 8601 duration format
      // https://en.wikipedia.org/wiki/ISO_8601#Durations
      "P3Y6M4DT12H30M5S"
      P the start of the duration representation.
      Y the number of years.
      M the number of months.
      W the number of weeks.
      D the number of days.
      T of the representation.
      H the number of hours.
      M the number of minutes.
      S the number of seconds.
      */
    
      const fmt = (n) => Math.abs(n) || 0;
    
      const toISO = function (diff) {
        let iso = 'P';
        iso += fmt(diff.years) + 'Y';
        iso += fmt(diff.months) + 'M';
        iso += fmt(diff.days) + 'DT';
        iso += fmt(diff.hours) + 'H';
        iso += fmt(diff.minutes) + 'M';
        iso += fmt(diff.seconds) + 'S';
        return iso
      };
      var toISO$1 = toISO;
    
      //get number of hours/minutes... between the two dates
      function getDiff(a, b) {
        const isBefore = a.isBefore(b);
        const later = isBefore ? b : a;
        let earlier = isBefore ? a : b;
        earlier = earlier.clone();
        const diff = {
          years: 0,
          months: 0,
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        };
        Object.keys(diff).forEach((unit) => {
          if (earlier.isSame(later, unit)) {
            return
          }
          let max = earlier.diff(later, unit);
          earlier = earlier.add(max, unit);
          diff[unit] = max;
        });
        //reverse it, if necessary
        if (isBefore) {
          Object.keys(diff).forEach((u) => {
            if (diff[u] !== 0) {
              diff[u] *= -1;
            }
          });
        }
        return diff
      }
    
      //our conceptual 'break-points' for each unit
      const qualifiers = {
        months: {
          almost: 10,
          over: 4
        },
        days: {
          almost: 25,
          over: 10
        },
        hours: {
          almost: 20,
          over: 8
        },
        minutes: {
          almost: 50,
          over: 20
        },
        seconds: {
          almost: 50,
          over: 20
        }
      };
    
      // Expects a plural unit arg
      function pluralize(value, unit) {
        if (value === 1) {
          unit = unit.slice(0, -1);
        }
        return value + ' ' + unit
      }
    
      const toSoft = function (diff) {
        let rounded = null;
        let qualified = null;
        let abbreviated = [];
        let englishValues = [];
        //go through each value and create its text-representation
        Object.keys(diff).forEach((unit, i, units) => {
          const value = Math.abs(diff[unit]);
          if (value === 0) {
            return
          }
          abbreviated.push(value + unit[0]);
          const englishValue = pluralize(value, unit);
          englishValues.push(englishValue);
          if (!rounded) {
            rounded = qualified = englishValue;
            if (i > 4) {
              return
            }
            //is it a 'almost' something, etc?
            const nextUnit = units[i + 1];
            const nextValue = Math.abs(diff[nextUnit]);
            if (nextValue > qualifiers[nextUnit].almost) {
              rounded = pluralize(value + 1, unit);
              qualified = 'almost ' + rounded;
            } else if (nextValue > qualifiers[nextUnit].over) {
              qualified = 'over ' + englishValue;
            }
          }
        });
        return { qualified, rounded, abbreviated, englishValues }
      };
      var soften = toSoft;
    
      //by spencermountain + Shaun Grady
    
      //create the human-readable diff between the two dates
      const since = (start, end) => {
        end = beADate(end, start);
        const diff = getDiff(start, end);
        const isNow = Object.keys(diff).every((u) => !diff[u]);
        if (isNow === true) {
          return {
            diff,
            rounded: 'now',
            qualified: 'now',
            precise: 'now',
            abbreviated: [],
            iso: 'P0Y0M0DT0H0M0S',
            direction: 'present',
          }
        }
        let precise;
        let direction = 'future';
    
        let { rounded, qualified, englishValues, abbreviated } = soften(diff);
    
        //make them into a string
        precise = englishValues.splice(0, 2).join(', ');
        //handle before/after logic
        if (start.isAfter(end) === true) {
          rounded += ' ago';
          qualified += ' ago';
          precise += ' ago';
          direction = 'past';
        } else {
          rounded = 'in ' + rounded;
          qualified = 'in ' + qualified;
          precise = 'in ' + precise;
        }
        // https://en.wikipedia.org/wiki/ISO_8601#Durations
        // P[n]Y[n]M[n]DT[n]H[n]M[n]S 
        let iso = toISO$1(diff);
        return {
          diff,
          rounded,
          qualified,
          precise,
          abbreviated,
          iso,
          direction,
        }
      };
    
      var since$1 = since;
    
      //https://www.timeanddate.com/calendar/aboutseasons.html
      // Spring - from March 1 to May 31;
      // Summer - from June 1 to August 31;
      // Fall (autumn) - from September 1 to November 30; and,
      // Winter - from December 1 to February 28 (February 29 in a leap year).
      const north = [
        ['spring', 2, 1],
        ['summer', 5, 1],
        ['fall', 8, 1],
        ['autumn', 8, 1],
        ['winter', 11, 1] //dec 1
      ];
      const south = [
        ['fall', 2, 1],
        ['autumn', 2, 1],
        ['winter', 5, 1],
        ['spring', 8, 1],
        ['summer', 11, 1] //dec 1
      ];
    
      var seasons = { north, south };
    
      var quarters = [
        null,
        [0, 1], //jan 1
        [3, 1], //apr 1
        [6, 1], //july 1
        [9, 1] //oct 1
      ];
    
      const units$1 = {
        minute: (s) => {
          walkTo$1(s, {
            second: 0,
            millisecond: 0
          });
          return s
        },
        quarterhour: (s) => {
          let minute = s.minutes();
          if (minute >= 45) {
            s = s.minutes(45);
          } else if (minute >= 30) {
            s = s.minutes(30);
          } else if (minute >= 15) {
            s = s.minutes(15);
          } else {
            s = s.minutes(0);
          }
          walkTo$1(s, {
            second: 0,
            millisecond: 0
          });
          return s
        },
        hour: (s) => {
          walkTo$1(s, {
            minute: 0,
            second: 0,
            millisecond: 0
          });
          return s
        },
        day: (s) => {
          walkTo$1(s, {
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
          return s
        },
        week: (s) => {
          let original = s.clone();
          s = s.day(s._weekStart); //monday
          if (s.isAfter(original)) {
            s = s.subtract(1, 'week');
          }
          walkTo$1(s, {
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
          return s
        },
        month: (s) => {
          walkTo$1(s, {
            date: 1,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
          return s
        },
        quarter: (s) => {
          let q = s.quarter();
          if (quarters[q]) {
            walkTo$1(s, {
              month: quarters[q][0],
              date: quarters[q][1],
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0
            });
          }
          return s
        },
        season: (s) => {
          let current = s.season();
          let hem = 'north';
          if (s.hemisphere() === 'South') {
            hem = 'south';
          }
          for (let i = 0; i < seasons[hem].length; i++) {
            if (seasons[hem][i][0] === current) {
              //winter goes between years
              let year = s.year();
              if (current === 'winter' && s.month() < 3) {
                year -= 1;
              }
              walkTo$1(s, {
                year,
                month: seasons[hem][i][1],
                date: seasons[hem][i][2],
                hour: 0,
                minute: 0,
                second: 0,
                millisecond: 0
              });
              return s
            }
          }
          return s
        },
        year: (s) => {
          walkTo$1(s, {
            month: 0,
            date: 1,
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0
          });
          return s
        },
        decade: (s) => {
          s = s.startOf('year');
          let year = s.year();
          let decade = parseInt(year / 10, 10) * 10;
          s = s.year(decade);
          return s
        },
        century: (s) => {
          s = s.startOf('year');
          let year = s.year();
          // near 0AD goes '-1 | +1'
          let decade = parseInt(year / 100, 10) * 100;
          s = s.year(decade);
          return s
        }
      };
      units$1.date = units$1.day;
    
      const startOf = (a, unit) => {
        let s = a.clone();
        unit = normalize$2(unit);
        if (units$1[unit]) {
          return units$1[unit](s)
        }
        if (unit === 'summer' || unit === 'winter') {
          s = s.season(unit);
          return units$1.season(s)
        }
        return s
      };
    
      //piggy-backs off startOf
      const endOf = (a, unit) => {
        let s = a.clone();
        unit = normalize$2(unit);
        if (units$1[unit]) {
          // go to beginning, go to next one, step back 1ms
          s = units$1[unit](s); // startof
          s = s.add(1, unit);
          s = s.subtract(1, 'millisecond');
          return s
        }
        return s
      };
    
      //is it 'wednesday'?
      const isDay = function (unit) {
        if (short().find((s) => s === unit)) {
          return true
        }
        if (long().find((s) => s === unit)) {
          return true
        }
        return false
      };
    
      // return a list of the weeks/months/days between a -> b
      // returns spacetime objects in the timezone of the input
      const every = function (start, unit, end) {
        if (!unit || !end) {
          return []
        }
        //cleanup unit param
        unit = normalize$2(unit);
        //cleanup to param
        end = start.clone().set(end);
        //swap them, if they're backwards
        if (start.isAfter(end)) {
          let tmp = start;
          start = end;
          end = tmp;
        }
    
        //support 'every wednesday'
        let d = start.clone();
        if (isDay(unit)) {
          d = d.next(unit);
          unit = 'week';
        } else {
          let first = d.startOf(unit);
          if (first.isBefore(start)) {
            d = d.next(unit);
          }
        }
        //okay, actually start doing it
        let result = [];
        while (d.isBefore(end)) {
          result.push(d);
          d = d.add(1, unit);
        }
        return result
      };
      var every$1 = every;
    
      const parseDst = dst => {
        if (!dst) {
          return []
        }
        return dst.split('->')
      };
    
      const titleCase = str => {
        str = str[0].toUpperCase() + str.substr(1);
        str = str.replace(/\/gmt/, '/GMT');
        str = str.replace(/[\/_]([a-z])/gi, s => {
          return s.toUpperCase()
        });
        return str
      };
    
      //get metadata about this timezone
      const timezone = s => {
        let zones = s.timezones;
        let tz = s.tz;
        if (zones.hasOwnProperty(tz) === false) {
          tz = findTz(s.tz, zones);
        }
        if (tz === null) {
          if (s.silent === false) {
            console.warn("Warn: could not find given or local timezone - '" + s.tz + "'");
          }
          return {
            current: {
              epochShift: 0
            }
          }
        }
        let found = zones[tz];
        let result = {
          name: titleCase(tz),
          hasDst: Boolean(found.dst),
          default_offset: found.offset,
          //do north-hemisphere version as default (sorry!)
          hemisphere: found.hem === 's' ? 'South' : 'North',
          current: {}
        };
    
        if (result.hasDst) {
          let arr = parseDst(found.dst);
          result.change = {
            start: arr[0],
            back: arr[1]
          };
        }
        //find the offsets for summer/winter times
        //(these variable names are north-centric)
        let summer = found.offset; // (july)
        let winter = summer; // (january) assume it's the same for now
        if (result.hasDst === true) {
          if (result.hemisphere === 'North') {
            winter = summer - 1;
          } else {
            //southern hemisphere
            winter = found.offset + 1;
          }
        }
    
        //find out which offset to use right now
        //use 'summer' time july-time
        if (result.hasDst === false) {
          result.current.offset = summer;
          result.current.isDST = false;
        } else if (inSummerTime$1(s.epoch, result.change.start, result.change.back, summer, winter) === true) {
          result.current.offset = summer;
          result.current.isDST = result.hemisphere === 'North'; //dst 'on' in winter in north
        } else {
          //use 'winter' january-time
          result.current.offset = winter;
          result.current.isDST = result.hemisphere === 'South'; //dst 'on' in summer in south
        }
        return result
      };
      var timezone$1 = timezone;
    
      const units = [
        'century',
        'decade',
        'year',
        'month',
        'date',
        'day',
        'hour',
        'minute',
        'second',
        'millisecond'
      ];
    
      //the spacetime instance methods (also, the API)
      const methods$4 = {
        set: function (input, tz) {
          let s = this.clone();
          s = handleInput(s, input, null);
          if (tz) {
            this.tz = findTz(tz);
          }
          return s
        },
        timezone: function () {
          return timezone$1(this)
        },
        isDST: function () {
          return timezone$1(this).current.isDST
        },
        hasDST: function () {
          return timezone$1(this).hasDst
        },
        offset: function () {
          return timezone$1(this).current.offset * 60
        },
        hemisphere: function () {
          return timezone$1(this).hemisphere
        },
        format: function (fmt) {
          return format$1(this, fmt)
        },
        unixFmt: function (fmt) {
          return unixFmt$1(this, fmt)
        },
        startOf: function (unit) {
          return startOf(this, unit)
        },
        endOf: function (unit) {
          return endOf(this, unit)
        },
        leapYear: function () {
          let year = this.year();
          return isLeapYear(year)
        },
        progress: function (unit) {
          return progress$1(this, unit)
        },
        nearest: function (unit) {
          return nearest$1(this, unit)
        },
        diff: function (d, unit) {
          return diff(this, d, unit)
        },
        since: function (d) {
          if (!d) {
            d = this.clone().set();
          }
          return since$1(this, d)
        },
        next: function (unit) {
          let s = this.add(1, unit);
          return s.startOf(unit)
        },
        //the start of the previous year/week/century
        last: function (unit) {
          let s = this.subtract(1, unit);
          return s.startOf(unit)
        },
        isValid: function () {
          //null/undefined epochs
          if (!this.epoch && this.epoch !== 0) {
            return false
          }
          return !isNaN(this.d.getTime())
        },
        //travel to this timezone
        goto: function (tz) {
          let s = this.clone();
          s.tz = findTz(tz, s.timezones); //science!
          return s
        },
        //get each week/month/day between a -> b
        every: function (unit, to) {
          // allow swapping these params:
          if (typeof unit === 'object' && typeof to === 'string') {
            let tmp = to;
            to = unit;
            unit = tmp;
          }
          return every$1(this, unit, to)
        },
        isAwake: function () {
          let hour = this.hour();
          //10pm -> 8am
          if (hour < 8 || hour > 22) {
            return false
          }
          return true
        },
        isAsleep: function () {
          return !this.isAwake()
        },
        daysInMonth: function () {
          switch (this.month()) {
            case 0:
              return 31
            case 1:
              return this.leapYear() ? 29 : 28
            case 2:
              return 31
            case 3:
              return 30
            case 4:
              return 31
            case 5:
              return 30
            case 6:
              return 31
            case 7:
              return 31
            case 8:
              return 30
            case 9:
              return 31
            case 10:
              return 30
            case 11:
              return 31
            default:
              throw new Error('Invalid Month state.')
          }
        },
        //pretty-printing
        log: function () {
          console.log('');
          console.log(format$1(this, 'nice-short'));
          return this
        },
        logYear: function () {
          console.log('');
          console.log(format$1(this, 'full-short'));
          return this
        },
        json: function () {
          return units.reduce((h, unit) => {
            h[unit] = this[unit]();
            return h
          }, {})
        },
        debug: function () {
          let tz = this.timezone();
          let date = this.format('MM') + ' ' + this.format('date-ordinal') + ' ' + this.year();
          date += '\n     - ' + this.format('time');
          console.log('\n\n', date + '\n     - ' + tz.name + ' (' + tz.current.offset + ')');
          return this
        },
        //alias of 'since' but opposite - like moment.js
        from: function (d) {
          d = this.clone().set(d);
          return d.since(this)
        },
        fromNow: function () {
          let d = this.clone().set(Date.now());
          return d.since(this)
        },
        weekStart: function (input) {
          //accept a number directly
          if (typeof input === 'number') {
            this._weekStart = input;
            return this
          }
          if (typeof input === 'string') {
            // accept 'wednesday'
            input = input.toLowerCase().trim();
            let num = short().indexOf(input);
            if (num === -1) {
              num = long().indexOf(input);
            }
            if (num === -1) {
              num = 1; //go back to default
            }
            this._weekStart = num;
          } else {
            console.warn('Spacetime Error: Cannot understand .weekStart() input:', input);
          }
          return this
        }
      };
      // aliases
      methods$4.inDST = methods$4.isDST;
      methods$4.round = methods$4.nearest;
      methods$4.each = methods$4.every;
      var methods$5 = methods$4;
    
      // javascript setX methods like setDate() can't be used because of the local bias
    
      const validate = (n) => {
        //handle number as a string
        if (typeof n === 'string') {
          n = parseInt(n, 10);
        }
        return n
      };
    
      const order$1 = ['year', 'month', 'date', 'hour', 'minute', 'second', 'millisecond'];
    
      //reduce hostile micro-changes when moving dates by millisecond
      const confirm = (s, tmp, unit) => {
        let n = order$1.indexOf(unit);
        let arr = order$1.slice(n, order$1.length);
        for (let i = 0; i < arr.length; i++) {
          let want = tmp[arr[i]]();
          s[arr[i]](want);
        }
        return s
      };
    
      // allow specifying setter direction
      const fwdBkwd = function (s, old, goFwd, unit) {
        if (goFwd === true && s.isBefore(old)) {
          s = s.add(1, unit);
        } else if (goFwd === false && s.isAfter(old)) {
          s = s.minus(1, unit);
        }
        return s
      };
    
      const milliseconds = function (s, n) {
        n = validate(n);
        let current = s.millisecond();
        let diff = current - n; //milliseconds to shift by
        return s.epoch - diff
      };
    
      const seconds = function (s, n, goFwd) {
        n = validate(n);
        let old = s.clone();
        let diff = s.second() - n;
        let shift = diff * ms.second;
        s.epoch = s.epoch - shift;
        s = fwdBkwd(s, old, goFwd, 'minute'); // specify direction
        return s.epoch
      };
    
      const minutes = function (s, n, goFwd) {
        n = validate(n);
        let old = s.clone();
        let diff = s.minute() - n;
        let shift = diff * ms.minute;
        s.epoch -= shift;
        confirm(s, old, 'second');
        s = fwdBkwd(s, old, goFwd, 'hour'); // specify direction
        return s.epoch
      };
    
      const hours = function (s, n, goFwd) {
        n = validate(n);
        if (n >= 24) {
          n = 24;
        } else if (n < 0) {
          n = 0;
        }
        let old = s.clone();
        let diff = s.hour() - n;
        let shift = diff * ms.hour;
        s.epoch -= shift;
        // oops, did we change the day?
        if (s.date() !== old.date()) {
          s = old.clone();
          if (diff > 1) {
            diff -= 1;
          }
          if (diff < 1) {
            diff += 1;
          }
          shift = diff * ms.hour;
          s.epoch -= shift;
        }
        walkTo$1(s, {
          hour: n
        });
        confirm(s, old, 'minute');
        s = fwdBkwd(s, old, goFwd, 'day'); // specify direction
        return s.epoch
      };
    
      const time = function (s, str, goFwd) {
        let m = str.match(/([0-9]{1,2})[:h]([0-9]{1,2})(:[0-9]{1,2})? ?(am|pm)?/);
        if (!m) {
          //fallback to support just '2am'
          m = str.match(/([0-9]{1,2}) ?(am|pm)/);
          if (!m) {
            return s.epoch
          }
          m.splice(2, 0, '0'); //add implicit 0 minutes
          m.splice(3, 0, ''); //add implicit seconds
        }
        let h24 = false;
        let hour = parseInt(m[1], 10);
        let minute = parseInt(m[2], 10);
        if (minute >= 60) {
          minute = 59;
        }
        if (hour > 12) {
          h24 = true;
        }
        //make the hour into proper 24h time
        if (h24 === false) {
          if (m[4] === 'am' && hour === 12) {
            //12am is midnight
            hour = 0;
          }
          if (m[4] === 'pm' && hour < 12) {
            //12pm is noon
            hour += 12;
          }
        }
        // handle seconds
        m[3] = m[3] || '';
        m[3] = m[3].replace(/:/, '');
        let sec = parseInt(m[3], 10) || 0;
        let old = s.clone();
        s = s.hour(hour);
        s = s.minute(minute);
        s = s.second(sec);
        s = s.millisecond(0);
        s = fwdBkwd(s, old, goFwd, 'day'); // specify direction
        return s.epoch
      };
    
      const date = function (s, n, goFwd) {
        n = validate(n);
        //avoid setting february 31st
        if (n > 28) {
          let month = s.month();
          let max = monthLength[month];
          // support leap day in february
          if (month === 1 && n === 29 && isLeapYear(s.year())) {
            max = 29;
          }
          if (n > max) {
            n = max;
          }
        }
        //avoid setting < 0
        if (n <= 0) {
          n = 1;
        }
        let old = s.clone();
        walkTo$1(s, {
          date: n
        });
        s = fwdBkwd(s, old, goFwd, 'month'); // specify direction
        return s.epoch
      };
    
      const month = function (s, n, goFwd) {
        if (typeof n === 'string') {
          n = mapping$1()[n.toLowerCase()];
        }
        n = validate(n);
        //don't go past december
        if (n >= 12) {
          n = 11;
        }
        if (n <= 0) {
          n = 0;
        }
    
        let d = s.date();
        //there's no 30th of february, etc.
        if (d > monthLength[n]) {
          //make it as close as we can..
          d = monthLength[n];
        }
        let old = s.clone();
        walkTo$1(s, {
          month: n,
          d
        });
        s = fwdBkwd(s, old, goFwd, 'year'); // specify direction
        return s.epoch
      };
    
      const year = function (s, n) {
        // support '97
        if (typeof n === 'string' && /^'[0-9]{2}$/.test(n)) {
          n = n.replace(/'/, '').trim();
          n = Number(n);
          // '89 is 1989
          if (n > 30) {
            //change this in 10y
            n = 1900 + n;
          } else {
            // '12 is 2012
            n = 2000 + n;
          }
        }
        n = validate(n);
        walkTo$1(s, {
          year: n
        });
        return s.epoch
      };
    
      const week = function (s, n, goFwd) {
        let old = s.clone();
        n = validate(n);
        s = s.month(0);
        s = s.date(1);
        s = s.day('monday');
        //first week starts first Thurs in Jan
        // so mon dec 28th is 1st week
        // so mon dec 29th is not the week
        if (s.monthName() === 'december' && s.date() >= 28) {
          s = s.add(1, 'week');
        }
        n -= 1; //1-based
        s = s.add(n, 'weeks');
        s = fwdBkwd(s, old, goFwd, 'year'); // specify direction
        return s.epoch
      };
    
      const dayOfYear = function (s, n, goFwd) {
        n = validate(n);
        let old = s.clone();
        n -= 1; //days are 1-based
        if (n <= 0) {
          n = 0;
        } else if (n >= 365) {
          n = 364;
        }
        s = s.startOf('year');
        s = s.add(n, 'day');
        confirm(s, old, 'hour');
        s = fwdBkwd(s, old, goFwd, 'year'); // specify direction
        return s.epoch
      };
    
      const methods$3 = {
        millisecond: function (num) {
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = milliseconds(s, num);
            return s
          }
          return this.d.getMilliseconds()
        },
        second: function (num, goFwd) {
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = seconds(s, num, goFwd);
            return s
          }
          return this.d.getSeconds()
        },
        minute: function (num, goFwd) {
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = minutes(s, num, goFwd);
            return s
          }
          return this.d.getMinutes()
        },
        hour: function (num, goFwd) {
          let d = this.d;
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = hours(s, num, goFwd);
            return s
          }
          return d.getHours()
        },
    
        //'3:30' is 3.5
        hourFloat: function (num, goFwd) {
          if (num !== undefined) {
            let s = this.clone();
            let minute = num % 1;
            minute = minute * 60;
            let hour = parseInt(num, 10);
            s.epoch = hours(s, hour, goFwd);
            s.epoch = minutes(s, minute, goFwd);
            return s
          }
          let d = this.d;
          let hour = d.getHours();
          let minute = d.getMinutes();
          minute = minute / 60;
          return hour + minute
        },
    
        // hour in 12h format
        hour12: function (str, goFwd) {
          let d = this.d;
          if (str !== undefined) {
            let s = this.clone();
            str = '' + str;
            let m = str.match(/^([0-9]+)(am|pm)$/);
            if (m) {
              let hour = parseInt(m[1], 10);
              if (m[2] === 'pm') {
                hour += 12;
              }
              s.epoch = hours(s, hour, goFwd);
            }
            return s
          }
          //get the hour
          let hour12 = d.getHours();
          if (hour12 > 12) {
            hour12 = hour12 - 12;
          }
          if (hour12 === 0) {
            hour12 = 12;
          }
          return hour12
        },
    
        //some ambiguity here with 12/24h
        time: function (str, goFwd) {
          if (str !== undefined) {
            let s = this.clone();
            str = str.toLowerCase().trim();
            s.epoch = time(s, str, goFwd);
            return s
          }
          return `${this.h12()}:${zeroPad(this.minute())}${this.ampm()}`
        },
    
        // either 'am' or 'pm'
        ampm: function (input, goFwd) {
          let which = 'am';
          let hour = this.hour();
          if (hour >= 12) {
            which = 'pm';
          }
          if (typeof input !== 'string') {
            return which
          }
          //okay, we're doing a setter
          let s = this.clone();
          input = input.toLowerCase().trim();
          //ampm should never change the day
          // - so use `.hour(n)` instead of `.minus(12,'hour')`
          if (hour >= 12 && input === 'am') {
            //noon is 12pm
            hour -= 12;
            return s.hour(hour, goFwd)
          }
          if (hour < 12 && input === 'pm') {
            hour += 12;
            return s.hour(hour, goFwd)
          }
          return s
        },
    
        //some hard-coded times of day, like 'noon'
        dayTime: function (str, goFwd) {
          if (str !== undefined) {
            const times = {
              morning: '7:00am',
              breakfast: '7:00am',
              noon: '12:00am',
              lunch: '12:00pm',
              afternoon: '2:00pm',
              evening: '6:00pm',
              dinner: '6:00pm',
              night: '11:00pm',
              midnight: '23:59pm'
            };
            let s = this.clone();
            str = str || '';
            str = str.toLowerCase();
            if (times.hasOwnProperty(str) === true) {
              s = s.time(times[str], goFwd);
            }
            return s
          }
          let h = this.hour();
          if (h < 6) {
            return 'night'
          }
          if (h < 12) {
            //until noon
            return 'morning'
          }
          if (h < 17) {
            //until 5pm
            return 'afternoon'
          }
          if (h < 22) {
            //until 10pm
            return 'evening'
          }
          return 'night'
        },
    
        //parse a proper iso string
        iso: function (num) {
          if (num !== undefined) {
            return this.set(num)
          }
          return this.format('iso')
        }
      };
      var timeFns = methods$3;
    
      const methods$2 = {
        // # day in the month
        date: function (num, goFwd) {
          if (num !== undefined) {
            let s = this.clone();
            num = parseInt(num, 10);
            if (num) {
              s.epoch = date(s, num, goFwd);
            }
            return s
          }
          return this.d.getDate()
        },
    
        //like 'wednesday' (hard!)
        day: function (input, goFwd) {
          if (input === undefined) {
            return this.d.getDay()
          }
          let original = this.clone();
          let want = input;
          // accept 'wednesday'
          if (typeof input === 'string') {
            input = input.toLowerCase();
            if (aliases$1.hasOwnProperty(input)) {
              want = aliases$1[input];
            } else {
              want = short().indexOf(input);
              if (want === -1) {
                want = long().indexOf(input);
              }
            }
          }
          //move approx
          let day = this.d.getDay();
          let diff = day - want;
          if (goFwd === true && diff > 0) {
            diff = diff - 7;
          }
          if (goFwd === false && diff < 0) {
            diff = diff + 7;
          }
          let s = this.subtract(diff, 'days');
          //tighten it back up
          walkTo$1(s, {
            hour: original.hour(),
            minute: original.minute(),
            second: original.second()
          });
          return s
        },
    
        //these are helpful name-wrappers
        dayName: function (input, goFwd) {
          if (input === undefined) {
            return long()[this.day()]
          }
          let s = this.clone();
          s = s.day(input, goFwd);
          return s
        }
      };
      var dateFns = methods$2;
    
      const clearMinutes = (s) => {
        s = s.minute(0);
        s = s.second(0);
        s = s.millisecond(1);
        return s
      };
    
      const methods$1 = {
        // day 0-366
        dayOfYear: function (num, goFwd) {
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = dayOfYear(s, num, goFwd);
            return s
          }
          //days since newyears - jan 1st is 1, jan 2nd is 2...
          let sum = 0;
          let month = this.d.getMonth();
          let tmp;
          //count the num days in each month
          for (let i = 1; i <= month; i++) {
            tmp = new Date();
            tmp.setDate(1);
            tmp.setFullYear(this.d.getFullYear()); //the year matters, because leap-years
            tmp.setHours(1);
            tmp.setMinutes(1);
            tmp.setMonth(i);
            tmp.setHours(-2); //the last day of the month
            sum += tmp.getDate();
          }
          return sum + this.d.getDate()
        },
    
        //since the start of the year
        week: function (num, goFwd) {
          // week-setter
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = week(this, num, goFwd);
            s = clearMinutes(s);
            return s
          }
          //find-out which week it is
          let tmp = this.clone();
          tmp = tmp.month(0);
          tmp = tmp.date(1);
          tmp = clearMinutes(tmp);
          tmp = tmp.day('monday');
          //don't go into last-year
          if (tmp.monthName() === 'december' && tmp.date() >= 28) {
            tmp = tmp.add(1, 'week');
          }
          // is first monday the 1st?
          let toAdd = 1;
          if (tmp.date() === 1) {
            toAdd = 0;
          }
          tmp = tmp.minus(1, 'second');
          const thisOne = this.epoch;
          //if the week technically hasn't started yet
          if (tmp.epoch > thisOne) {
            return 1
          }
          //speed it up, if we can
          let i = 0;
          let skipWeeks = this.month() * 4;
          tmp.epoch += ms.week * skipWeeks;
          i += skipWeeks;
          for (; i <= 52; i++) {
            if (tmp.epoch > thisOne) {
              return i + toAdd
            }
            tmp = tmp.add(1, 'week');
          }
          return 52
        },
        //either name or number
        month: function (input, goFwd) {
          if (input !== undefined) {
            let s = this.clone();
            s.epoch = month(s, input, goFwd);
            return s
          }
          return this.d.getMonth()
        },
        //'january'
        monthName: function (input, goFwd) {
          if (input !== undefined) {
            let s = this.clone();
            s = s.month(input, goFwd);
            return s
          }
          return long$1()[this.month()]
        },
    
        //q1, q2, q3, q4
        quarter: function (num, goFwd) {
          if (num !== undefined) {
            if (typeof num === 'string') {
              num = num.replace(/^q/i, '');
              num = parseInt(num, 10);
            }
            if (quarters[num]) {
              let s = this.clone();
              let month = quarters[num][0];
              s = s.month(month, goFwd);
              s = s.date(1, goFwd);
              s = s.startOf('day');
              return s
            }
          }
          let month = this.d.getMonth();
          for (let i = 1; i < quarters.length; i++) {
            if (month < quarters[i][0]) {
              return i - 1
            }
          }
          return 4
        },
    
        //spring, summer, winter, fall
        season: function (input, goFwd) {
          let hem = 'north';
          if (this.hemisphere() === 'South') {
            hem = 'south';
          }
          if (input !== undefined) {
            let s = this.clone();
            for (let i = 0; i < seasons[hem].length; i++) {
              if (input === seasons[hem][i][0]) {
                s = s.month(seasons[hem][i][1], goFwd);
                s = s.date(1);
                s = s.startOf('day');
              }
            }
            return s
          }
          let month = this.d.getMonth();
          for (let i = 0; i < seasons[hem].length - 1; i++) {
            if (month >= seasons[hem][i][1] && month < seasons[hem][i + 1][1]) {
              return seasons[hem][i][0]
            }
          }
          return 'winter'
        },
    
        //the year number
        year: function (num) {
          if (num !== undefined) {
            let s = this.clone();
            s.epoch = year(s, num);
            return s
          }
          return this.d.getFullYear()
        },
    
        //bc/ad years
        era: function (str) {
          if (str !== undefined) {
            let s = this.clone();
            str = str.toLowerCase();
            //TODO: there is no year-0AD i think. may have off-by-1 error here
            let year$1 = s.d.getFullYear();
            //make '1992' into 1992bc..
            if (str === 'bc' && year$1 > 0) {
              s.epoch = year(s, year$1 * -1);
            }
            //make '1992bc' into '1992'
            if (str === 'ad' && year$1 < 0) {
              s.epoch = year(s, year$1 * -1);
            }
            return s
          }
          if (this.d.getFullYear() < 0) {
            return 'BC'
          }
          return 'AD'
        },
    
        // 2019 -> 2010
        decade: function (input) {
          if (input !== undefined) {
            input = String(input);
            input = input.replace(/([0-9])'?s$/, '$1'); //1950's
            input = input.replace(/([0-9])(th|rd|st|nd)/, '$1'); //fix ordinals
            if (!input) {
              console.warn('Spacetime: Invalid decade input');
              return this
            }
            // assume 20th century?? for '70s'.
            if (input.length === 2 && /[0-9][0-9]/.test(input)) {
              input = '19' + input;
            }
            let year = Number(input);
            if (isNaN(year)) {
              return this
            }
            // round it down to the decade
            year = Math.floor(year / 10) * 10;
            return this.year(year) //.startOf('decade')
          }
          return this.startOf('decade').year()
        },
        // 1950 -> 19+1
        century: function (input) {
          if (input !== undefined) {
            if (typeof input === 'string') {
              input = input.replace(/([0-9])(th|rd|st|nd)/, '$1'); //fix ordinals
              input = input.replace(/([0-9]+) ?(b\.?c\.?|a\.?d\.?)/i, (a, b, c) => {
                if (c.match(/b\.?c\.?/i)) {
                  b = '-' + b;
                }
                return b
              });
              input = input.replace(/c$/, ''); //20thC
            }
            let year = Number(input);
            if (isNaN(input)) {
              console.warn('Spacetime: Invalid century input');
              return this
            }
            // there is no century 0
            if (year === 0) {
              year = 1;
            }
            if (year >= 0) {
              year = (year - 1) * 100;
            } else {
              year = (year + 1) * 100;
            }
            return this.year(year)
          }
          // century getter
          let num = this.startOf('century').year();
          num = Math.floor(num / 100);
          if (num < 0) {
            return num - 1
          }
          return num + 1
        },
        // 2019 -> 2+1
        millenium: function (input) {
          if (input !== undefined) {
            if (typeof input === 'string') {
              input = input.replace(/([0-9])(th|rd|st|nd)/, '$1'); //fix ordinals
              input = Number(input);
              if (isNaN(input)) {
                console.warn('Spacetime: Invalid millenium input');
                return this
              }
            }
            if (input > 0) {
              input -= 1;
            }
            let year = input * 1000;
            // there is no year 0
            if (year === 0) {
              year = 1;
            }
            return this.year(year)
          }
          // get the current millenium
          let num = Math.floor(this.year() / 1000);
          if (num >= 0) {
            num += 1;
          }
          return num
        }
      };
      var yearFns = methods$1;
    
      const methods = Object.assign({}, timeFns, dateFns, yearFns);
    
      //aliases
      methods.milliseconds = methods.millisecond;
      methods.seconds = methods.second;
      methods.minutes = methods.minute;
      methods.hours = methods.hour;
      methods.hour24 = methods.hour;
      methods.h12 = methods.hour12;
      methods.h24 = methods.hour24;
      methods.days = methods.day;
    
      const addMethods$4 = Space => {
        //hook the methods into prototype
        Object.keys(methods).forEach(k => {
          Space.prototype[k] = methods[k];
        });
      };
    
      var queryFns = addMethods$4;
    
      const getMonthLength = function (month, year) {
        if (month === 1 && isLeapYear(year)) {
          return 29
        }
        return monthLength[month]
      };
    
      //month is the one thing we 'model/compute'
      //- because ms-shifting can be off by enough
      const rollMonth = (want, old) => {
        //increment year
        if (want.month > 0) {
          let years = parseInt(want.month / 12, 10);
          want.year = old.year() + years;
          want.month = want.month % 12;
        } else if (want.month < 0) {
          let m = Math.abs(want.month);
          let years = parseInt(m / 12, 10);
          if (m % 12 !== 0) {
            years += 1;
          }
          want.year = old.year() - years;
          //ignore extras
          want.month = want.month % 12;
          want.month = want.month + 12;
          if (want.month === 12) {
            want.month = 0;
          }
        }
        return want
      };
    
      // briefly support day=-2 (this does not need to be perfect.)
      const rollDaysDown = (want, old, sum) => {
        want.year = old.year();
        want.month = old.month();
        let date = old.date();
        want.date = date - Math.abs(sum);
        while (want.date < 1) {
          want.month -= 1;
          if (want.month < 0) {
            want.month = 11;
            want.year -= 1;
          }
          let max = getMonthLength(want.month, want.year);
          want.date += max;
        }
        return want
      };
    
      // briefly support day=33 (this does not need to be perfect.)
      const rollDaysUp = (want, old, sum) => {
        let year = old.year();
        let month = old.month();
        let max = getMonthLength(month, year);
        while (sum > max) {
          sum -= max;
          month += 1;
          if (month >= 12) {
            month -= 12;
            year += 1;
          }
          max = getMonthLength(month, year);
        }
        want.month = month;
        want.date = sum;
        return want
      };
    
      const months = rollMonth;
      const days = rollDaysUp;
      const daysBack = rollDaysDown;
    
      // this logic is a bit of a mess,
      // but briefly:
      // millisecond-math, and some post-processing covers most-things
      // we 'model' the calendar here only a little bit
      // and that usually works-out...
    
      const order = ['millisecond', 'second', 'minute', 'hour', 'date', 'month'];
      let keep = {
        second: order.slice(0, 1),
        minute: order.slice(0, 2),
        quarterhour: order.slice(0, 2),
        hour: order.slice(0, 3),
        date: order.slice(0, 4),
        month: order.slice(0, 4),
        quarter: order.slice(0, 4),
        season: order.slice(0, 4),
        year: order,
        decade: order,
        century: order
      };
      keep.week = keep.hour;
      keep.season = keep.date;
      keep.quarter = keep.date;
    
      // Units need to be dst adjuested
      const dstAwareUnits = {
        year: true,
        quarter: true,
        season: true,
        month: true,
        week: true,
        date: true
      };
    
      const keepDate = {
        month: true,
        quarter: true,
        season: true,
        year: true
      };
    
      const addMethods$3 = (SpaceTime) => {
        SpaceTime.prototype.add = function (num, unit) {
          let s = this.clone();
    
          if (!unit || num === 0) {
            return s //don't bother
          }
          let old = this.clone();
          unit = normalize$2(unit);
          if (unit === 'millisecond') {
            s.epoch += num;
            return s
          }
          // support 'fortnight' alias
          if (unit === 'fortnight') {
            num *= 2;
            unit = 'week';
          }
          //move forward by the estimated milliseconds (rough)
          if (ms[unit]) {
            s.epoch += ms[unit] * num;
          } else if (unit === 'week' || unit === 'weekend') {
            s.epoch += ms.day * (num * 7);
          } else if (unit === 'quarter' || unit === 'season') {
            s.epoch += ms.month * (num * 3);
          } else if (unit === 'quarterhour') {
            s.epoch += ms.minute * 15 * num;
          }
          //now ensure our milliseconds/etc are in-line
          let want = {};
          if (keep[unit]) {
            keep[unit].forEach((u) => {
              want[u] = old[u]();
            });
          }
    
          if (dstAwareUnits[unit]) {
            const diff = old.timezone().current.offset - s.timezone().current.offset;
            s.epoch += diff * 3600 * 1000;
          }
    
          //ensure month/year has ticked-over
          if (unit === 'month') {
            want.month = old.month() + num;
            //month is the one unit we 'model' directly
            want = months(want, old);
          }
          //support coercing a week, too
          if (unit === 'week') {
            let sum = old.date() + num * 7;
            if (sum <= 28 && sum > 1) {
              want.date = sum;
            }
          }
          if (unit === 'weekend' && s.dayName() !== 'saturday') {
            s = s.day('saturday', true); //ensure it's saturday
          }
          //support 25-hour day-changes on dst-changes
          else if (unit === 'date') {
            if (num < 0) {
              want = daysBack(want, old, num);
            } else {
              //specify a naive date number, if it's easy to do...
              let sum = old.date() + num;
              // ok, model this one too
              want = days(want, old, sum);
            }
            //manually punt it if we haven't moved at all..
            if (num !== 0 && old.isSame(s, 'day')) {
              want.date = old.date() + num;
            }
          }
          // ensure a quarter is 3 months over
          else if (unit === 'quarter') {
            want.month = old.month() + num * 3;
            want.year = old.year();
            // handle rollover
            if (want.month < 0) {
              let years = Math.floor(want.month / 12);
              let remainder = want.month + Math.abs(years) * 12;
              want.month = remainder;
              want.year += years;
            } else if (want.month >= 12) {
              let years = Math.floor(want.month / 12);
              want.month = want.month % 12;
              want.year += years;
            }
            want.date = old.date();
          }
          //ensure year has changed (leap-years)
          else if (unit === 'year') {
            let wantYear = old.year() + num;
            let haveYear = s.year();
            if (haveYear < wantYear) {
              let toAdd = Math.floor(num / 4) || 1; //approx num of leap-days
              s.epoch += Math.abs(ms.day * toAdd);
            } else if (haveYear > wantYear) {
              let toAdd = Math.floor(num / 4) || 1; //approx num of leap-days
              s.epoch += ms.day * toAdd;
            }
          }
          //these are easier
          else if (unit === 'decade') {
            want.year = s.year() + 10;
          } else if (unit === 'century') {
            want.year = s.year() + 100;
          }
          //keep current date, unless the month doesn't have it.
          if (keepDate[unit]) {
            let max = monthLength[want.month];
            want.date = old.date();
            if (want.date > max) {
              want.date = max;
            }
          }
          if (Object.keys(want).length > 1) {
            walkTo$1(s, want);
          }
          return s
        };
    
        //subtract is only add *-1
        SpaceTime.prototype.subtract = function (num, unit) {
          let s = this.clone();
          return s.add(num * -1, unit)
        };
        //add aliases
        SpaceTime.prototype.minus = SpaceTime.prototype.subtract;
        SpaceTime.prototype.plus = SpaceTime.prototype.add;
      };
    
      var addFns = addMethods$3;
    
      //make a string, for easy comparison between dates
      const print = {
        millisecond: (s) => {
          return s.epoch
        },
        second: (s) => {
          return [s.year(), s.month(), s.date(), s.hour(), s.minute(), s.second()].join('-')
        },
        minute: (s) => {
          return [s.year(), s.month(), s.date(), s.hour(), s.minute()].join('-')
        },
        hour: (s) => {
          return [s.year(), s.month(), s.date(), s.hour()].join('-')
        },
        day: (s) => {
          return [s.year(), s.month(), s.date()].join('-')
        },
        week: (s) => {
          return [s.year(), s.week()].join('-')
        },
        month: (s) => {
          return [s.year(), s.month()].join('-')
        },
        quarter: (s) => {
          return [s.year(), s.quarter()].join('-')
        },
        year: (s) => {
          return s.year()
        }
      };
      print.date = print.day;
    
      const addMethods$2 = (SpaceTime) => {
        SpaceTime.prototype.isSame = function (b, unit, tzAware = true) {
          let a = this;
          if (!unit) {
            return null
          }
          // support swapped params
          if (typeof b === 'string' && typeof unit === 'object') {
            let tmp = b;
            b = unit;
            unit = tmp;
          }
          if (typeof b === 'string' || typeof b === 'number') {
            b = new SpaceTime(b, this.timezone.name);
          }
          //support 'seconds' aswell as 'second'
          unit = unit.replace(/s$/, '');
    
          // make them the same timezone for proper comparison
          if (tzAware === true && a.tz !== b.tz) {
            b = b.clone();
            b.tz = a.tz;
          }
          if (print[unit]) {
            return print[unit](a) === print[unit](b)
          }
          return null
        };
      };
    
      var sameFns = addMethods$2;
    
      const addMethods$1 = SpaceTime => {
        const methods = {
          isAfter: function (d) {
            d = beADate(d, this);
            let epoch = getEpoch(d);
            if (epoch === null) {
              return null
            }
            return this.epoch > epoch
          },
          isBefore: function (d) {
            d = beADate(d, this);
            let epoch = getEpoch(d);
            if (epoch === null) {
              return null
            }
            return this.epoch < epoch
          },
          isEqual: function (d) {
            d = beADate(d, this);
            let epoch = getEpoch(d);
            if (epoch === null) {
              return null
            }
            return this.epoch === epoch
          },
          isBetween: function (start, end, isInclusive = false) {
            start = beADate(start, this);
            end = beADate(end, this);
            let startEpoch = getEpoch(start);
            if (startEpoch === null) {
              return null
            }
            let endEpoch = getEpoch(end);
            if (endEpoch === null) {
              return null
            }
            if (isInclusive) {
              return this.isBetween(start, end) || this.isEqual(start) || this.isEqual(end);
            }
            return startEpoch < this.epoch && this.epoch < endEpoch
          }
        };
    
        //hook them into proto
        Object.keys(methods).forEach(k => {
          SpaceTime.prototype[k] = methods[k];
        });
      };
    
      var compareFns = addMethods$1;
    
      const addMethods = SpaceTime => {
        const methods = {
          i18n: data => {
            //change the day names
            if (isObject(data.days)) {
              set$1(data.days);
            }
            //change the month names
            if (isObject(data.months)) {
              set$2(data.months);
            }
    
            // change the the display style of the month / day names
            if (isBoolean(data.useTitleCase)) {
              set(data.useTitleCase);
            }
          }
        };
    
        //hook them into proto
        Object.keys(methods).forEach(k => {
          SpaceTime.prototype[k] = methods[k];
        });
      };
    
      var i18nFns = addMethods;
    
      let timezones = zones;
      //fake timezone-support, for fakers (es5 class)
      const SpaceTime = function (input, tz, options = {}) {
        //the holy moment
        this.epoch = null;
        //the shift for the given timezone
        this.tz = findTz(tz, timezones);
        //whether to output warnings to console
        this.silent = options.silent || true;
        // favour british interpretation of 02/02/2018, etc
        this.british = options.dmy || options.british;
    
        //does the week start on sunday, or monday:
        this._weekStart = 1; //default to monday
        if (options.weekStart !== undefined) {
          this._weekStart = options.weekStart;
        }
        // the reference today date object, (for testing)
        this._today = {};
        if (options.today !== undefined) {
          this._today = options.today;
        }
        // dunno if this is a good idea, or not
        // Object.defineProperty(this, 'parsers', {
        //   enumerable: false,
        //   writable: true,
        //   value: parsers
        // })
        //add getter/setters
        Object.defineProperty(this, 'd', {
          //return a js date object
          get: function () {
            let offset = quickOffset$1(this);
            //every computer is somewhere- get this computer's built-in offset
            let bias = new Date(this.epoch).getTimezoneOffset() || 0;
            //movement
            let shift = bias + offset * 60; //in minutes
            shift = shift * 60 * 1000; //in ms
            //remove this computer's offset
            let epoch = this.epoch + shift;
            let d = new Date(epoch);
            return d
          }
        });
        //add this data on the object, to allow adding new timezones
        Object.defineProperty(this, 'timezones', {
          get: () => timezones,
          set: (obj) => {
            timezones = obj;
            return obj
          }
        });
        //parse the various formats
        let tmp = handleInput(this, input);
        this.epoch = tmp.epoch;
      };
    
      //(add instance methods to prototype)
      Object.keys(methods$5).forEach((k) => {
        SpaceTime.prototype[k] = methods$5[k];
      });
    
      // ¯\_(ツ)_/¯
      SpaceTime.prototype.clone = function () {
        return new SpaceTime(this.epoch, this.tz, {
          silent: this.silent,
          weekStart: this._weekStart,
          today: this._today,
          parsers: this.parsers
        })
      };
    
      /**
       * @deprecated use toNativeDate()
       * @returns native date object at the same epoch
       */
      SpaceTime.prototype.toLocalDate = function () {
        return this.toNativeDate()
      };
    
      /**
       * @returns native date object at the same epoch
       */
      SpaceTime.prototype.toNativeDate = function () {
        return new Date(this.epoch)
      };
    
      //append more methods
      queryFns(SpaceTime);
      addFns(SpaceTime);
      sameFns(SpaceTime);
      compareFns(SpaceTime);
      i18nFns(SpaceTime);
    
      var Spacetime = SpaceTime;
    
      // const timezones = require('../data');
    
      const whereIts = (a, b) => {
        let start = new Spacetime(null);
        let end = new Spacetime(null);
        start = start.time(a);
        //if b is undefined, use as 'within one hour'
        if (b) {
          end = end.time(b);
        } else {
          end = start.add(59, 'minutes');
        }
    
        let startHour = start.hour();
        let endHour = end.hour();
        let tzs = Object.keys(start.timezones).filter((tz) => {
          if (tz.indexOf('/') === -1) {
            return false
          }
          let m = new Spacetime(null, tz);
          let hour = m.hour();
          //do 'calendar-compare' not real-time-compare
          if (hour >= startHour && hour <= endHour) {
            //test minutes too, if applicable
            if (hour === startHour && m.minute() < start.minute()) {
              return false
            }
            if (hour === endHour && m.minute() > end.minute()) {
              return false
            }
            return true
          }
          return false
        });
        return tzs
      };
      var whereIts$1 = whereIts;
    
      var version = '7.1.0';
    
      const main = (input, tz, options) => new Spacetime(input, tz, options);
    
      // set all properties of a given 'today' object
      const setToday = function (s) {
        let today = s._today || {};
        Object.keys(today).forEach((k) => {
          s = s[k](today[k]);
        });
        return s
      };
    
      //some helper functions on the main method
      main.now = (tz, options) => {
        let s = new Spacetime(new Date().getTime(), tz, options);
        s = setToday(s);
        return s
      };
      main.today = (tz, options) => {
        let s = new Spacetime(new Date().getTime(), tz, options);
        s = setToday(s);
        return s.startOf('day')
      };
      main.tomorrow = (tz, options) => {
        let s = new Spacetime(new Date().getTime(), tz, options);
        s = setToday(s);
        return s.add(1, 'day').startOf('day')
      };
      main.yesterday = (tz, options) => {
        let s = new Spacetime(new Date().getTime(), tz, options);
        s = setToday(s);
        return s.subtract(1, 'day').startOf('day')
      };
      main.extend = function (obj = {}) {
        Object.keys(obj).forEach((k) => {
          Spacetime.prototype[k] = obj[k];
        });
        return this
      };
      main.timezones = function () {
        let s = new Spacetime();
        return s.timezones
      };
      main.max = function (tz, options) {
        let s = new Spacetime(null, tz, options);
        s.epoch = 8640000000000000;
        return s
      };
      main.min = function (tz, options) {
        let s = new Spacetime(null, tz, options);
        s.epoch = -8640000000000000;
        return s
      };
    
      //find tz by time
      main.whereIts = whereIts$1;
      main.version = version;
    
      //aliases:
      main.plugin = main.extend;
    
      return main;
    
    }));
    
    /**
     * Base settings
     */
    const
    configBase = {
        // 3 modes and their settings
        'mode': [
            /*
            - development and testing mode is compare by url
            - if url of development/testing mode is not match -> automatically will be set production mode
            - for each mode can be set:
                - debug: true/false => if is set true and existing some write to console -> will be show
                - baseURl: url for ajax req. for backend
             */
            {
                'name': 'development',
                //'url': [
                //    'www.ek-webtracking-testing-web.local',
                //    'www.ek-webtracking.local'
                //],
                'debug': true,
                'baseUrl': 'http://www.playground-ek-tracking.local/ajaxrequest.php?action=',
            },
            {
                'name': 'testing',
                //'url': [
                //    'tr-fe-test.emailkampane.cz'
                //],
                'debug': true,
                'baseUrl': 'https://tr-be-test.emailkampane.cz/api.php?action=',
            },
            {
                // production mode must be always last as default choice !
                'name': 'production',
                'debug': false,
                'baseUrl': 'https://tr-be.emailkampane.cz/api.php?action=',
            }
        ],
        // Base request data structure
        'baseRequestData': function () {
            let spacetimeLib = spacetime();
    
            return {
                'event': '',
                'web_id': '',
                'user_id': '',
                'token': '',
                'user_agent': navigator.userAgent,
                'user_is_logged': false,
                'date': spacetimeLib.format('{year}-{iso-month}-{date-pad} {hour-24-pad}:{minute-pad}:{second-pad}'),
                'timezone_offset': parseInt(spacetimeLib.format('offset'))
            };
        },
        'getConfigurationForActualMode': function(requestedMode = '') {
            let
                //hostname = location.hostname,
                actualMode = false;
    
            for(let i = 0; i < configBase.mode.length; i++ ) {
                let mode = configBase.mode[i];
    
                if(mode.hasOwnProperty('name') && mode['name'] === requestedMode) {
                    actualMode = mode;
                    break;
                }
    
                //if(mode.hasOwnProperty('url') && mode['url'].indexOf(hostname) > -1) {
                //    actualMode = mode;
                //    break;
                //}
            }
    
            return actualMode === false?configBase.mode[(configBase.mode.length -1)]:actualMode;
        }
    };
    
    
    const
        configMode = configBase.getConfigurationForActualMode(existingFunction.m); // development | testing | production

    /**
     * List of cookie variables and their settings (expiration in days)
     */
    const
    configCookieVariables = {
        'userID': {
            'name': '_ektracker_uid',
            'expiration': 365
        }
    };
    /**
     * Referrer settings
     */
    const
        configReferrer = {
            // URL for redirect from email campaign (can be just part)
            'campaignUrlRedirect': 'emailkampane.cz',
            // Number of last part of url pathname are GUID for tracking
            'numberOfGuidFromUrlPath': 3
        };
    /**
     * Settings for campaign guide manager
     */
    const
        configCampaignGuide = {
            'transferTable': [
                // from is var. get in URL, to is name for backend
                {
                    'from': 'rg0',
                    'to': 'referrer_guid_0'
                },
                {
                    'from': 'rg1',
                    'to': 'referrer_guid_1'
                },
                {
                    'from': 'rg2',
                    'to': 'referrer_guid_2'
                }
            ]
        };
    // campaign guide manager
    var campaignGuide = (function () {
        var getGUIDFromUrl = function (url) {
            let arrUrl = url.split('?');
            if(arrUrl.length < 2) {
                return false;
            }
            let
                arrListQueryString = arrUrl[1].split('&'),
                out = [];
            for(let x = 0; x < arrListQueryString.length; x++) {
                for(let i = 0; i < configCampaignGuide.transferTable.length; i++) {
                    let from = configCampaignGuide.transferTable[i]['from'];
                    if(arrListQueryString[x].indexOf(from) === 0) {
                        let arrValue = arrListQueryString[x].split('=');
                        if(arrValue.length === 2) {
                            out.push({
                                'name': configCampaignGuide.transferTable[i]['to'],
                                'value': arrValue[1]
                            });
                        }
                    }
                }
            }
            return (out.length > 0?out:false);
        }
        var getGUIDFromLocalStorage = function () {
            let out = [];
            for(let i=0; i<configCampaignGuide.transferTable.length; i++) {
                let localStorageKey = configCampaignGuide.transferTable[i]['to'],
                    guid = localStorage.getItem(localStorageKey);
                if(guid !== undefined && guid !== null) {
                    out.push({
                        'name': localStorageKey,
                        'value': guid
                    });
                    localStorage.removeItem(localStorageKey);
                }
            }
            return (out.length > 0?out:false);
        }
        return {
            getGUIDFromUrl: getGUIDFromUrl,
            getGUIDFromLocalStorage: getGUIDFromLocalStorage
        }
    })();
    /**
     * List of event types
     */
    const
    configEventType = {
        init: 'init',
        saveLoggedUser: 'saveLoggedUser',
        sendData: 'sendData'
    };
    
    /**
     * List of events and their settings
     *
     * Types:
     * - init: only save webID
     * - sendData: send data for backend
     */
    const
    configEvent = {
        'init': {
            type: configEventType.init,
            expectedNumberOfParameters: 2,
        },
        'loggedUser': {
            type: configEventType.saveLoggedUser,
            expectedNumberOfParameters: 2,
        },
        'pageVisit': {
            type: configEventType.sendData,
            urlEndpoint: 'pageVisit',
            expectedNumberOfParameters: 1,
            dataMapper: function (parameters) {
                let out = {
                    'url': document.location.href,
                    'title': document.title
                },
                    referrerUrl = referrer.get(),
                    arrGuid = campaignGuide.getGUIDFromLocalStorage();
                out['referrer'] = referrerUrl === false?false:referrerUrl;
                if(arrGuid !== false) {
                    for(let i = 0; i < arrGuid.length; i++) {
                        out[arrGuid[i]['name']] = arrGuid[i]['value'];
                    }
                }
                return out;
            }
        },
        'timeSpendOnPage': {
            type: configEventType.sendData,
            urlEndpoint: 'timeSpendOnPage',
            expectedNumberOfParameters: 3,
            dataMapper: function (parameters) {
                return {
                    'time_on_page': parameters[1],
                    'callback_id': parameters[2]
                };
            }
        },
        'addToCart':  {
            type: configEventType.sendData,
            urlEndpoint: 'addToCart',
            expectedNumberOfParameters: 2,
            dataMapper: function (parameters) {
                return parameters[1];
            }
        },
        'removeFromCart': {
            type: configEventType.sendData,
            urlEndpoint: 'removeFromCart',
            expectedNumberOfParameters: 2,
            dataMapper: function (parameters) {
                return parameters[1];
            }
        },
        'updateCart': {
            type: configEventType.sendData,
            urlEndpoint: 'updateCart',
            expectedNumberOfParameters: 2,
            dataMapper: function (parameters) {
                return parameters[1];
            }
        },
        'orderSubmitted': {
            type: configEventType.sendData,
            urlEndpoint: 'orderSubmitted',
            expectedNumberOfParameters: 2,
            dataMapper: function (parameters) {
    
                if(!parameters[1].hasOwnProperty('sourceShopType')) {
                    parameters[1]['sourceShopType'] = 0;
                }
    
                return parameters[1];
            }
        }
    };
    /*
    * Patrim umistit do: src/components/config/robots.js
    */
    const
        configRobots = [
                "Googlebot\/",
                "Googlebot-Mobile",
                "Googlebot-Image",
                "Googlebot-News",
                "Googlebot-Video",
                "AdsBot-Google([^-]|$)",
                "AdsBot-Google-Mobile",
                "Feedfetcher-Google",
                "Mediapartners-Google",
                "Mediapartners \(Googlebot\)",
                "APIs-Google",
                "bingbot",
                "Slurp",
                "[wW]get",
                "LinkedInBot",
                "Python-urllib",
                "python-requests",
                "aiohttp",
                "httpx",
                "libwww-perl",
                "httpunit",
                "nutch",
                "Go-http-client",
                "phpcrawl",
                "msnbot",
                "jyxobot",
                "FAST-WebCrawler",
                "FAST Enterprise Crawler",
                "BIGLOTRON",
                "Teoma",
                "convera",
                "seekbot",
                "Gigabot",
                "Gigablast",
                "exabot",
                "ia_archiver",
                "GingerCrawler",
                "webmon ",
                "HTTrack",
                "grub.org",
                "UsineNouvelleCrawler",
                "antibot",
                "netresearchserver",
                "speedy",
                "fluffy",
                "findlink",
                "msrbot",
                "panscient",
                "yacybot",
                "AISearchBot",
                "ips-agent",
                "tagoobot",
                "MJ12bot",
                "woriobot",
                "yanga",
                "buzzbot",
                "mlbot",
                "YandexBot",
                "YandexImages",
                "YandexAccessibilityBot",
                "YandexMobileBot",
                "YandexMetrika",
                "YandexTurbo",
                "YandexImageResizer",
                "YandexVideo",
                "YandexAdNet",
                "YandexBlogs",
                "YandexCalendar",
                "YandexDirect",
                "YandexFavicons",
                "YaDirectFetcher",
                "YandexForDomain",
                "YandexMarket",
                "YandexMedia",
                "YandexMobileScreenShotBot",
                "YandexNews",
                "YandexOntoDB",
                "YandexPagechecker",
                "YandexPartner",
                "YandexRCA",
                "YandexSearchShop",
                "YandexSitelinks",
                "YandexSpravBot",
                "YandexTracker",
                "YandexVertis",
                "YandexVerticals",
                "YandexWebmaster",
                "YandexScreenshotBot",
                "purebot",
                "Linguee Bot",
                "CyberPatrol",
                "voilabot",
                "Baiduspider",
                "citeseerxbot",
                "spbot",
                "twengabot",
                "postrank",
                "TurnitinBot",
                "scribdbot",
                "page2rss",
                "sitebot",
                "linkdex",
                "Adidxbot",
                "ezooms",
                "dotbot",
                "Mail.RU_Bot",
                "discobot",
                "heritrix",
                "findthatfile",
                "europarchive.org",
                "NerdByNature.Bot",
                "sistrix crawler",
                "Ahrefs(Bot|SiteAudit)",
                "fuelbot",
                "CrunchBot",
                "IndeedBot",
                "mappydata",
                "woobot",
                "ZoominfoBot",
                "PrivacyAwareBot",
                "Multiviewbot",
                "SWIMGBot",
                "Grobbot",
                "eright",
                "Apercite",
                "semanticbot",
                "Aboundex",
                "domaincrawler",
                "wbsearchbot",
                "summify",
                "CCBot",
                "edisterbot",
                "seznambot",
                "ec2linkfinder",
                "gslfbot",
                "aiHitBot",
                "intelium_bot",
                "facebookexternalhit",
                "Yeti",
                "RetrevoPageAnalyzer",
                "lb-spider",
                "Sogou",
                "lssbot",
                "careerbot",
                "wotbox",
                "wocbot",
                "ichiro",
                "DuckDuckBot",
                "lssrocketcrawler",
                "drupact",
                "webcompanycrawler",
                "acoonbot",
                "openindexspider",
                "gnam gnam spider",
                "web-archive-net.com.bot",
                "backlinkcrawler",
                "coccoc",
                "integromedb",
                "content crawler spider",
                "toplistbot",
                "it2media-domain-crawler",
                "ip-web-crawler.com",
                "siteexplorer.info",
                "elisabot",
                "proximic",
                "changedetection",
                "arabot",
                "WeSEE:Search",
                "niki-bot",
                "CrystalSemanticsBot",
                "rogerbot",
                "360Spider",
                "psbot",
                "InterfaxScanBot",
                "CC Metadata Scaper",
                "g00g1e.net",
                "GrapeshotCrawler",
                "urlappendbot",
                "brainobot",
                "fr-crawler",
                "binlar",
                "SimpleCrawler",
                "Twitterbot",
                "cXensebot",
                "smtbot",
                "bnf.fr_bot",
                "A6-Indexer",
                "ADmantX",
                "Facebot",
                "OrangeBot\/",
                "memorybot",
                "AdvBot",
                "MegaIndex",
                "SemanticScholarBot",
                "ltx71",
                "nerdybot",
                "xovibot",
                "BUbiNG",
                "Qwantify",
                "archive.org_bot",
                "Applebot",
                "TweetmemeBot",
                "crawler4j",
                "findxbot",
                "S[eE][mM]rushBot",
                "yoozBot",
                "lipperhey",
                "Y!J",
                "Domain Re-Animator Bot",
                "AddThis",
                "Screaming Frog SEO Spider",
                "MetaURI",
                "Scrapy",
                "Livelap[bB]ot",
                "OpenHoseBot",
                "CapsuleChecker",
                "collection@infegy.com",
                "IstellaBot",
                "DeuSu\/",
                "betaBot",
                "Cliqzbot\/",
                "MojeekBot\/",
                "netEstate NE Crawler",
                "SafeSearch microdata crawler",
                "Gluten Free Crawler\/",
                "Sonic",
                "Sysomos",
                "Trove",
                "deadlinkchecker",
                "Slack-ImgProxy",
                "Embedly",
                "RankActiveLinkBot",
                "iskanie",
                "SafeDNSBot",
                "SkypeUriPreview",
                "Veoozbot",
                "Slackbot",
                "redditbot",
                "datagnionbot",
                "Google-Adwords-Instant",
                "adbeat_bot",
                "WhatsApp",
                "contxbot",
                "pinterest.com.bot",
                "electricmonk",
                "GarlikCrawler",
                "BingPreview\/",
                "vebidoobot",
                "FemtosearchBot",
                "Yahoo Link Preview",
                "MetaJobBot",
                "DomainStatsBot",
                "mindUpBot",
                "Daum\/",
                "Jugendschutzprogramm-Crawler",
                "Xenu Link Sleuth",
                "Pcore-HTTP",
                "moatbot",
                "KosmioBot",
                "[pP]ingdom",
                "AppInsights",
                "PhantomJS",
                "Gowikibot",
                "PiplBot",
                "Discordbot",
                "TelegramBot",
                "Jetslide",
                "newsharecounts",
                "James BOT",
                "Bark[rR]owler",
                "TinEye",
                "SocialRankIOBot",
                "trendictionbot",
                "Ocarinabot",
                "epicbot",
                "Primalbot",
                "DuckDuckGo-Favicons-Bot",
                "GnowitNewsbot",
                "Leikibot",
                "LinkArchiver",
                "YaK\/",
                "PaperLiBot",
                "Digg Deeper",
                "dcrawl",
                "Snacktory",
                "AndersPinkBot",
                "Fyrebot",
                "EveryoneSocialBot",
                "Mediatoolkitbot",
                "Luminator-robots",
                "ExtLinksBot",
                "SurveyBot",
                "NING\/",
                "okhttp",
                "Nuzzel",
                "omgili",
                "PocketParser",
                "YisouSpider",
                "um-LN",
                "ToutiaoSpider",
                "MuckRack",
                "Jamie's Spider",
                "AHC\/",
                "NetcraftSurveyAgent",
                "Laserlikebot",
                "^Apache-HttpClient",
                "AppEngine-Google",
                "Jetty",
                "Upflow",
                "Thinklab",
                "Traackr.com",
                "Twurly",
                "Mastodon",
                "http_get",
                "DnyzBot",
                "botify",
                "007ac9 Crawler",
                "BehloolBot",
                "BrandVerity",
                "check_http",
                "BDCbot",
                "ZumBot",
                "EZID",
                "ICC-Crawler",
                "ArchiveBot",
                "^LCC ",
                "filterdb.iss.net\/crawler",
                "BLP_bbot",
                "BomboraBot",
                "Buck\/",
                "Companybook-Crawler",
                "Genieo",
                "magpie-crawler",
                "MeltwaterNews",
                "Moreover",
                "newspaper\/",
                "ScoutJet",
                "(^| )sentry\/",
                "StorygizeBot",
                "UptimeRobot",
                "OutclicksBot",
                "seoscanners",
                "Hatena",
                "Google Web Preview",
                "MauiBot",
                "AlphaBot",
                "SBL-BOT",
                "IAS crawler",
                "adscanner",
                "Netvibes",
                "acapbot",
                "Baidu-YunGuanCe",
                "bitlybot",
                "blogmuraBot",
                "Bot.AraTurka.com",
                "bot-pge.chlooe.com",
                "BoxcarBot",
                "BTWebClient",
                "ContextAd Bot",
                "Digincore bot",
                "Disqus",
                "Feedly",
                "Fetch\/",
                "Fever",
                "Flamingo_SearchEngine",
                "FlipboardProxy",
                "g2reader-bot",
                "G2 Web Services",
                "imrbot",
                "K7MLWCBot",
                "Kemvibot",
                "Landau-Media-Spider",
                "linkapediabot",
                "vkShare",
                "Siteimprove.com",
                "BLEXBot\/",
                "DareBoost",
                "ZuperlistBot\/",
                "Miniflux\/",
                "Feedspot",
                "Diffbot\/",
                "SEOkicks",
                "tracemyfile",
                "Nimbostratus-Bot",
                "zgrab",
                "PR-CY.RU",
                "AdsTxtCrawler",
                "Datafeedwatch",
                "Zabbix",
                "TangibleeBot",
                "google-xrawler",
                "axios",
                "Amazon CloudFront",
                "Pulsepoint",
                "CloudFlare-AlwaysOnline",
                "Google-Structured-Data-Testing-Tool",
                "WordupInfoSearch",
                "WebDataStats",
                "HttpUrlConnection",
                "Seekport Crawler",
                "ZoomBot",
                "VelenPublicWebCrawler",
                "MoodleBot",
                "jpg-newsbot",
                "outbrain",
                "W3C_Validator",
                "Validator\.nu",
                "W3C-checklink",
                "W3C-mobileOK",
                "W3C_I18n-Checker",
                "FeedValidator",
                "W3C_CSS_Validator",
                "W3C_Unicorn",
                "Google-PhysicalWeb",
                "Blackboard",
                "ICBot\/",
                "BazQux",
                "Twingly",
                "Rivva",
                "Experibot",
                "awesomecrawler",
                "Dataprovider.com",
                "GroupHigh\/",
                "theoldreader.com",
                "AnyEvent",
                "Uptimebot\.org",
                "Nmap Scripting Engine",
                "2ip.ru",
                "Clickagy",
                "Caliperbot",
                "MBCrawler",
                "online-webceo-bot",
                "B2B Bot",
                "AddSearchBot",
                "Google Favicon",
                "HubSpot",
                "Chrome-Lighthouse",
                "HeadlessChrome",
                "CheckMarkNetwork\/",
                "www\.uptime\.com",
                "Streamline3Bot\/",
                "serpstatbot\/",
                "MixnodeCache\/",
                "^curl",
                "SimpleScraper",
                "RSSingBot",
                "Jooblebot",
                "fedoraplanet",
                "Friendica",
                "NextCloud",
                "Tiny Tiny RSS",
                "RegionStuttgartBot",
                "Bytespider",
                "Datanyze",
                "Google-Site-Verification",
                "TrendsmapResolver",
                "tweetedtimes",
                "NTENTbot",
                "Gwene",
                "SimplePie",
                "SearchAtlas",
                "Superfeedr",
                "feedbot",
                "UT-Dorkbot",
                "Amazonbot",
                "SerendeputyBot",
                "Eyeotabot",
                "officestorebot",
                "Neticle Crawler",
                "SurdotlyBot",
                "LinkisBot",
                "AwarioSmartBot",
                "AwarioRssBot",
                "RyteBot",
                "FreeWebMonitoring SiteChecker",
                "AspiegelBot",
                "NAVER Blog Rssbot",
                "zenback bot",
                "SentiBot",
                "Domains Project\/",
                "Pandalytics",
                "VKRobot",
                "bidswitchbot",
                "tigerbot",
                "NIXStatsbot",
                "Atom Feed Robot",
                "Curebot",
                "PagePeeker\/",
                "Vigil\/",
                "rssbot\/",
                "startmebot\/",
                "JobboerseBot",
                "seewithkids",
                "NINJA bot",
                "Cutbot",
                "BublupBot",
                "BrandONbot",
                "RidderBot",
                "Taboolabot",
                "Dubbotbot",
                "FindITAnswersbot",
                "infoobot",
                "Refindbot",
                "BlogTraffic\/\d\.\d+ Feed-Fetcher",
                "SeobilityBot",
                "Cincraw",
                "Dragonbot",
                "VoluumDSP-content-bot",
                "FreshRSS",
                "BitBot",
                "^PHP-Curl-Class",
                "Google-Certificates-Bridge",
                "centurybot",
                "Viber",
                "e\.ventures Investment Crawler",
                "evc-batch",
                "PetalBot",
                "virustotal",
                "(^| )PTST\/"
        ];
    /**
     * Attribute shortcut
     */
    const
    configAttributeShortcut = {
        'basic': {
            'event': 'e',
            'web_id': 'wi',
            'user_id': 'ui',
            'token': 'tk',
            'user_agent': 'ua',
            'date': 'd',
            'timezone_offset': 'tzo',
            'user_is_logged': 'uil',
            'time_on_page': 'top',
            'callback_id': 'ci',
            'referrer': 'r',
            'referrer_guid_0': 'rg0',
            'referrer_guid_1': 'rg1',
            'referrer_guid_2': 'rg2',
            'url': 'u',
            'title': 't',
            'currency': 'cu',
            'sourceShopType': 'sst',
            'order_number': 'on',
            'order_currency': 'ocu',
            'order_total_price_no_vat': 'otpnv',
            'order_total_price_with_vat': 'otpwv',
            'order_delivery_name': 'odn',
            'order_delivery_price_no_vat': 'odpnv',
            'order_delivery_vat': 'odv',
            'order_delivery_price_with_vat': 'odpwv',
            'order_payment_name': 'opn',
            'order_payment_price_no_vat': 'oppnv',
            'order_payment_vat': 'opv',
            'order_payment_price_with_vat': 'oppwv',
            'customer_name': 'cn',
            'customer_surname': 'cs',
            'customer_phone': 'cp',
            'customer_email': 'ce',
            'customer_street': 'cst',
            'customer_town': 'ct',
            'customer_postal_code': 'cpc',
            'customer_delivery_address_name': 'cdan',
            'customer_delivery_address_surname': 'cdas',
            'customer_delivery_address_street': 'cdast',
            'customer_delivery_address_town': 'cdat',
            'customer_delivery_address_postal_code': 'cdapc'
        },
        'loops': {
            //example below
            // 'products': {
            //     'shortcut': 'p',
            //     'divider': '_',
            //     'attributes': {
            //         'code': 'c',
            //         'name': 'n',
            //         'image': 'i',
            //         'variant': {},
            //         'url': 'u',
            //         'quantity': 'q',
            //         'price_no_vat': 'pnv',
            //         'vat': 'v',
            //         'price_with_vat': 'pwv'
            //     }
            // },
        },
        'products': {
            'shortcut': 'p',
            'divider': '_',
            'attributes': {
                'code': 'c',
                'name': 'n',
                'image': 'i',
                'variant': {},
                'url': 'u',
                'quantity': 'q',
                'price_no_vat': 'pnv',
                'vat': 'v',
                'price_with_vat': 'pwv'
            }
        },
    
        'productsVariant': {
            'shortcut': 'var',
            'divider': '_',
            'attributes': {
                'name': 'n',
                'value': 'v'
            }
        },
    };
    /**
     * Aja.js
     * Ajax without XML : Asynchronous Javascript and JavaScript/JSON(P)
     *
     * @author Bertrand Chevrier <chevrier.bertrand@gmail.com>
     * @license MIT
     */
    var aja =
    (function(){
        'use strict';
    
        /**
         * supported request types.
         * TODO support new types : 'style', 'file'?
         */
        var types = ['html', 'json', 'jsonp', 'script'];
    
        /**
         * supported http methods
         */
        var methods = [
            'connect',
            'delete',
            'get',
            'head',
            'options',
            'patch',
            'post',
            'put',
            'trace'
        ];
    
        /**
         * API entry point.
         * It creates an new {@link Aja} object.
         *
         * @example aja().url('page.html').into('#selector').go();
         *
         * @exports aja
         * @namespace aja
         * @returns {Aja} the {@link Aja} object ready to create your request.
         */
        var aja = function aja(){
    
            //contains all the values from the setter for this context.
            var data = {};
    
            //contains the bound events.
            var events = {};
    
            /**
             * The Aja object is your context, it provides your getter/setter
             * as well as methods the fluent way.
             * @typedef {Object} Aja
             */
    
            /**
             * @type {Aja}
             * @lends aja
             */
            var Aja = {
    
                /**
                 * URL getter/setter: where your request goes.
                 * All URL formats are supported: <pre>[protocol:][//][user[:passwd]@][host.tld]/path[?query][#hash]</pre>.
                 *
                 * @example aja().url('bestlib?pattern=aja');
                 *
                 * @throws TypeError
                 * @param {String} [url] - the url to set
                 * @returns {Aja|String} chains or get the URL
                 */
                url : function(url){
                    return _chain.call(this, 'url', url, validators.string);
                },
    
                /**
                 * Is the request synchronous (async by default) ?
                 *
                 * @example aja().sync(true);
                 *
                 * @param {Boolean|*} [sync] - true means sync (other types than booleans are casted)
                 * @returns {Aja|Boolean} chains or get the sync value
                 */
                sync : function(sync){
                    return _chain.call(this, 'sync', sync, validators.bool);
                },
    
                /**
                 * Should we force to disable browser caching (true by default) ?
                 * By setting this to false, then a buster will be added to the requests.
                 *
                 * @example aja().cache(false);
                 *
                 * @param {Boolean|*} [cache] - false means no cache  (other types than booleans are casted)
                 * @returns {Aja|Boolean} chains or get cache value
                 */
                cache : function(cache){
                    return _chain.call(this, 'cache', cache, validators.bool);
                },
    
                /**
                 * Type getter/setter: one of the predefined request type.
                 * The supported types are : <pre>['html', 'json', 'jsonp', 'script', 'style']</pre>.
                 * If not set, the default type is deduced regarding the context, but goes to json otherwise.
                 *
                 * @example aja().type('json');
                 *
                 * @throws TypeError if an unknown type is set
                 * @param {String} [type] - the type to set
                 * @returns {Aja|String} chains or get the type
                 */
                type : function(type){
                    return _chain.call(this, 'type', type, validators.type);
                },
    
                /**
                 * HTTP Request Header getter/setter.
                 *
                 * @example aja().header('Content-Type', 'application/json');
                 *
                 * @throws TypeError
                 * @param {String} name - the name of the header to get/set
                 * @param {String} [value] - the value of the header to set
                 * @returns {Aja|String} chains or get the header from the given name
                 */
                header : function(name, value){
                    data.headers = data.headers || {};
    
                    validators.string(name);
                    if(typeof value !== 'undefined'){
                        validators.string(value);
    
                        data.headers[name] = value;
    
                        return this;
                    }
    
                    return data.headers[name];
                },
    
                /**
                 * <strong>Setter only</strong> to add authentication credentials to the request.
                 *
                 * @throws TypeError
                 * @param {String} user - the user name
                 * @param {String} passwd - the password value
                 * @returns {Aja} chains
                 */
                auth : function(user, passwd){
                    //setter only
    
                    validators.string(user);
                    validators.string(passwd);
                    data.auth = {
                        user : user,
                        passwd : passwd
                    };
    
                    return this;
                },
    
                /**
                 * Sets a timeout (expressed in ms) after which it will halt the request and the 'timeout' event will be fired.
                 *
                 * @example aja().timeout(1000); // Terminate the request and fire the 'timeout' event after 1s
                 *
                 * @throws TypeError
                 * @param {Number} [ms] - timeout in ms to set. It has to be an integer > 0.
                 * @returns {Aja|String} chains or get the params
                 */
                timeout : function(ms){
                    return _chain.call(this, 'timeout', ms, validators.positiveInteger);
                },
    
                /**
                 * HTTP method getter/setter.
                 *
                 * @example aja().method('post');
                 *
                 * @throws TypeError if an unknown method is set
                 * @param {String} [method] - the method to set
                 * @returns {Aja|String} chains or get the method
                 */
                method : function(method){
                    return _chain.call(this, 'method', method, validators.method);
                },
    
                /**
                 * URL's queryString getter/setter. The parameters are ALWAYS appended to the URL.
                 *
                 * @example aja().queryString({ user : '12' }); //  ?user=12
                 *
                 * @throws TypeError
                 * @param {Object|String} [params] - key/values POJO or URL queryString directly to set
                 * @returns {Aja|String} chains or get the params
                 */
                queryString : function(params){
                    return _chain.call(this, 'queryString', params, validators.queryString);
                },
    
                /**
                 * URL's queryString getter/setter.
                 * Regarding the HTTP method the data goes to the queryString or the body.
                 *
                 * @example aja().data({ user : '12' });
                 *
                 * @throws TypeError
                 * @param {Object} [params] - key/values POJO to set
                 * @returns {Aja|String} chains or get the params
                 */
                data : function(params){
                    return _chain.call(this, 'data', params, validators.plainObject);
                },
    
                /**
                 * Request Body getter/setter.
                 * Objects and arrays are stringified (except FormData instances)
                 *
                 * @example aja().body(new FormData());
                 *
                 * @throws TypeError
                 * @param {String|Object|Array|Boolean|Number|FormData} [content] - the content value to set
                 * @returns {Aja|String|FormData} chains or get the body content
                 */
                body : function(content){
                    return _chain.call(this, 'body', content, null, function(content){
                        if(typeof content === 'object'){
                            //support FormData to be sent direclty
                            if( !(content instanceof FormData)){
                                //otherwise encode the object/array to a string
                                try {
                                    content = JSON.stringify(content);
                                } catch(e){
                                    throw new TypeError('Unable to stringify body\'s content : ' + e.name);
                                }
                                this.header('Content-Type', 'application/json');
                            }
                        } else {
                            content = content + ''; //cast
                        }
                        return content;
                    });
                },
    
                /**
                 * Into selector getter/setter. When you want an Element to contain the response.
                 *
                 * @example aja().into('div > .container');
                 *
                 * @throws TypeError
                 * @param {String|HTMLElement} [selector] - the dom query selector or directly the Element
                 * @returns {Aja|Array} chains or get the list of found elements
                 */
                into : function(selector){
                    return _chain.call(this, 'into', selector, validators.selector, function(selector){
                        if(typeof selector === 'string'){
                            return document.querySelectorAll(selector);
                        }
                        if(selector instanceof HTMLElement){
                            return [selector];
                        }
                    });
                },
    
                /**
                 * Padding name getter/setter, ie. the callback's PARAMETER name in your JSONP query.
                 *
                 * @example aja().jsonPaddingName('callback');
                 *
                 * @throws TypeError
                 * @param {String} [paramName] - a valid parameter name
                 * @returns {Aja|String} chains or get the parameter name
                 */
                jsonPaddingName : function(paramName){
                    return _chain.call(this, 'jsonPaddingName', paramName, validators.string);
                },
    
                /**
                 * Padding value  getter/setter, ie. the callback's name in your JSONP query.
                 *
                 * @example aja().jsonPadding('someFunction');
                 *
                 * @throws TypeError
                 * @param {String} [padding] - a valid function name
                 * @returns {Aja|String} chains or get the padding name
                 */
                jsonPadding : function(padding){
                    return _chain.call(this, 'jsonPadding', padding, validators.func);
                },
    
                /**
                 * Attach an handler to an event.
                 * Calling `on` with the same eventName multiple times add callbacks: they
                 * will all be executed.
                 *
                 * @example aja().on('success', function(res){ console.log('Cool', res);  });
                 *
                 * @param {String} name - the name of the event to listen
                 * @param {Function} cb - the callback to run once the event is triggered
                 * @returns {Aja} chains
                 */
                on : function(name, cb){
                    if(typeof cb === 'function'){
                        events[name] = events[name] || [];
                        events[name].push(cb);
                    }
                    return this;
                },
    
                /**
                 * Remove ALL handlers for an event.
                 *
                 * @example aja().off('success');
                 *
                 * @param {String} name - the name of the event
                 * @returns {Aja} chains
                 */
                off : function(name){
                    events[name] = [];
                    return this;
                },
    
                /**
                 * Trigger an event.
                 * This method will be called hardly ever outside Aja itself,
                 * but there is edge cases where it can be useful.
                 *
                 * @example aja().trigger('error', new Error('Emergency alert'));
                 *
                 * @param {String} name - the name of the event to trigger
                 * @param {*} data - arguments given to the handlers
                 * @returns {Aja} chains
                 */
                trigger : function(name, data){
                    var self = this;
                    var eventCalls  = function eventCalls(name, data){
                        if(events[name] instanceof Array){
                            events[name].forEach(function(event){
                                event.call(self, data);
                            });
                        }
                    };
                    if(typeof name !== 'undefined'){
                        name = name + '';
                        var statusPattern = /^([0-9])([0-9x])([0-9x])$/i;
                        var triggerStatus = name.match(statusPattern);
    
                        //HTTP status pattern
                        if(triggerStatus && triggerStatus.length > 3){
                            Object.keys(events).forEach(function(eventName){
                                var listenerStatus = eventName.match(statusPattern);
                                if(listenerStatus && listenerStatus.length > 3 &&       //an listener on status
                                    triggerStatus[1] === listenerStatus[1] &&           //hundreds match exactly
                                    (listenerStatus[2] === 'x' ||  triggerStatus[2] === listenerStatus[2]) && //tens matches
                                    (listenerStatus[3] === 'x' ||  triggerStatus[3] === listenerStatus[3])){ //ones matches
    
                                    eventCalls(eventName, data);
                                }
                            });
                            //or exact matching
                        } else if(events[name]){
                            eventCalls(name, data);
                        }
                    }
                    return this;
                },
    
                /**
                 * Trigger the call.
                 * This is the end of your chain loop.
                 *
                 * @example aja()
                 *           .url('data.json')
                 *           .on('200', function(res){
                 *               //Yeah !
                 *            })
                 *           .go();
                 */
                go : function(){
    
                    var type    = data.type || (data.into ? 'html' : 'json');
                    var url     = _buildQuery();
    
                    //delegates to ajaGo
                    if(typeof ajaGo[type] === 'function'){
                        return ajaGo[type].call(this, url);
                    }
                }
            };
    
            /**
             * Contains the different communication methods.
             * Used as provider by {@link Aja.go}
             *
             * @type {Object}
             * @private
             * @memberof aja
             */
            var ajaGo = {
    
                /**
                 * XHR call to url to retrieve JSON
                 * @param {String} url - the url
                 */
                json : function(url){
                    var self = this;
    
                    ajaGo._xhr.call(this, url, function processRes(res){
                        if(res){
                            try {
                                res = JSON.parse(res);
                            } catch(e){
                                self.trigger('error', e);
                                return null;
                            }
                        }
                        return res;
                    });
                },
    
                /**
                 * XHR call to url to retrieve HTML and add it to a container if set.
                 * @param {String} url - the url
                 */
                html : function(url){
                    ajaGo._xhr.call(this, url, function processRes(res){
                        if(data.into && data.into.length){
                            [].forEach.call(data.into, function(elt){
                                elt.innerHTML = res;
                            });
                        }
                        return res;
                    });
                },
    
                /**
                 * Create and send an XHR query.
                 * @param {String} url - the url
                 * @param {Function} processRes - to modify / process the response before sent to events.
                 */
                _xhr : function(url, processRes){
                    var self = this;
    
                    //iterators
                    var key, header;
    
                    var method      = data.method || 'get';
                    var async       = data.sync !== true;
                    var request     = new XMLHttpRequest();
                    var _data       = data.data;
                    var body        = data.body;
                    var headers     = data.headers || {};
                    var contentType = this.header('Content-Type');
                    var timeout     = data.timeout;
                    var timeoutId;
                    var isUrlEncoded;
                    var openParams;
    
                    //guess content type
                    if(!contentType && _data && _dataInBody()){
                        this.header('Content-Type', 'application/x-www-form-urlencoded;charset=utf-8');
                        contentType = this.header('Content-Type');
                    }
    
                    //if data is used in body, it needs some modifications regarding the content type
                    if(_data && _dataInBody()){
                        if(typeof body !== 'string'){
                            body = '';
                        }
    
                        if(contentType.indexOf('json') > -1){
                            try {
                                body = JSON.stringify(_data);
                            } catch(e){
                                throw new TypeError('Unable to stringify body\'s content : ' + e.name);
                            }
                        } else {
                            isUrlEncoded = contentType && contentType.indexOf('x-www-form-urlencoded') > 1;
                            for(key in _data){
                                if(isUrlEncoded){
                                    body += encodeURIComponent(key) + '=' + encodeURIComponent(_data[key]) + '&';
                                } else {
                                    body += key + '=' + _data[key] + '\n\r';
                                }
                            }
                        }
                    }
    
                    //open the XHR request
                    openParams = [method, url, async];
                    if(data.auth){
                        openParams.push(data.auth.user);
                        openParams.push(data.auth.passwd);
                    }
                    request.open.apply(request, openParams);
    
                    //set the headers
                    for(header in data.headers){
                        request.setRequestHeader(header, data.headers[header]);
                    }
    
                    //bind events
                    request.onprogress = function(e){
                        if (e.lengthComputable) {
                            self.trigger('progress', e.loaded / e.total);
                        }
                    };
    
                    request.onload = function onRequestLoad(){
                        var response = request.responseText;
    
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                        }
                        if(this.status >= 200 && this.status < 300){
                            if(typeof processRes === 'function'){
                                response = processRes(response);
                            }
                            self.trigger('success', response);
                        }
    
                        self.trigger(this.status, response);
    
                        self.trigger('end', response);
                    };
    
                    request.onerror = function onRequestError (err){
                        if (timeoutId) {
                            clearTimeout(timeoutId);
                        }
                        self.trigger('error', err, arguments);
                    };
    
                    //sets the timeout
                    if (timeout) {
                        timeoutId = setTimeout(function() {
                            self.trigger('timeout', {
                                type: 'timeout',
                                expiredAfter: timeout
                            }, request, arguments);
                            request.abort();
                        }, timeout);
                    }
    
                    //send the request
                    request.send(body);
                },
    
                /**
                 * @this {Aja} call bound to the Aja context
                 * @param {String} url - the url
                 */
                jsonp : function(url){
                    var script;
                    var self            = this;
                    var head            = document.querySelector('head');
                    var async           = data.sync !== true;
                    var jsonPaddingName = data.jsonPaddingName || 'callback';
                    var jsonPadding     = data.jsonPadding || ('_padd' + new Date().getTime() + Math.floor(Math.random() * 10000));
                    var paddingQuery    = {};
    
                    if(aja[jsonPadding]){
                        throw new Error('Padding ' + jsonPadding + '  already exists. It must be unique.');
                    }
                    if(!/^ajajsonp_/.test(jsonPadding)){
                        jsonPadding = 'ajajsonp_' + jsonPadding;
                    }
    
                    //window.ajajsonp = window.ajajsonp || {};
                    window[jsonPadding] = function padding (response){
                        self.trigger('success', response);
                        head.removeChild(script);
                        window[jsonPadding] = undefined;
                    };
    
                    paddingQuery[jsonPaddingName] = jsonPadding;
    
                    url =  appendQueryString(url, paddingQuery);
    
                    script = document.createElement('script');
                    script.async = async;
                    script.src = url;
                    script.onerror = function(){
                        self.trigger('error', arguments);
                        head.removeChild(script);
                        window[jsonPadding] = undefined;
                    };
                    head.appendChild(script);
                },
    
                /**
                 * Loads a script.
                 *
                 * This kind of ugly script loading is sometimes used by 3rd part libraries to load
                 * a configured script. For example, to embed google analytics or a twitter button.
                 *
                 * @this {Aja} call bound to the Aja context
                 * @param {String} url - the url
                 */
                script : function(url){
    
                    var self    = this;
                    var head    = document.querySelector('head') || document.querySelector('body');
                    var async   = data.sync !== true;
                    var script;
    
                    if(!head){
                        throw new Error('Ok, wait a second, you want to load a script, but you don\'t have at least a head or body tag...');
                    }
    
                    script = document.createElement('script');
                    script.async = async;
                    script.src = url;
                    script.onerror = function onScriptError(){
                        self.trigger('error', arguments);
                        head.removeChild(script);
                    };
                    script.onload = function onScriptLoad(){
                        self.trigger('success', arguments);
                    };
    
                    head.appendChild(script);
                }
            };
    
            /**
             * Helps you to chain getter/setters.
             * @private
             * @memberof aja
             * @this {Aja} bound to the current context
             * @param {String} name - the property name
             * @param {*} [value] - the property value if we are in a setter
             * @param {Function} [validator] - to validate/transform the value if needed
             * @param {Function} [update] - when there is more to do with the setter
             * @returns {Aja|*} either the current context (setter) or the requested value (getter)
             * @throws TypeError
             */
            var _chain = function _chain(name, value, validator, update){
                if(typeof value !== 'undefined'){
                    if(typeof validator === 'function'){
                        try{
                            value = validator.call(validators, value);
                        } catch(e){
                            throw new TypeError('Failed to set ' + name + ' : ' + e.message);
                        }
                    }
                    if(typeof update === 'function'){
                        data[name] = update.call(this, value);
                    } else {
                        data[name] = value;
                    }
                    return this;
                }
                return data[name] === 'undefined' ? null : data[name];
            };
    
            /**
             * Check whether the data must be set in the body instead of the queryString
             * @private
             * @memberof aja
             * @returns {Boolean} true id data goes to the body
             */
            var _dataInBody = function _dataInBody(){
                return ['delete', 'patch', 'post', 'put'].indexOf(data.method) > -1;
            };
    
            /**
             * Build the URL to run the request against.
             * @private
             * @memberof aja
             * @returns {String} the URL
             */
            var _buildQuery = function _buildQuery(){
    
                var url         = data.url;
                var cache       = typeof data.cache !== 'undefined' ? !!data.cache : true;
                var queryString = data.queryString || '';
                var _data       = data.data;
    
                //add a cache buster
                if(cache === false){
                    queryString += '&ajabuster=' + new Date().getTime();
                }
    
                url = appendQueryString(url, queryString);
    
                if(_data && !_dataInBody()){
                    url =  appendQueryString(url, _data);
                }
                return url;
            };
    
            //expose the Aja function
            return Aja;
        };
    
        /**
         * Validation/reparation rules for Aja's getter/setter.
         */
        var validators = {
    
            /**
             * cast to boolean
             * @param {*} value
             * @returns {Boolean} casted value
             */
            bool : function(value){
                return !!value;
            },
    
            /**
             * Check whether the given parameter is a string
             * @param {String} string
             * @returns {String} value
             * @throws {TypeError} for non strings
             */
            string : function(string){
                if(typeof string !== 'string'){
                    throw new TypeError('a string is expected, but ' + string + ' [' + (typeof string) + '] given');
                }
                return string;
            },
    
            /**
             * Check whether the given parameter is a positive integer > 0
             * @param {Number} integer
             * @returns {Number} value
             * @throws {TypeError} for non strings
             */
            positiveInteger : function(integer){
                if(parseInt(integer) !== integer || integer <= 0){
                    throw new TypeError('an integer is expected, but ' + integer + ' [' + (typeof integer) + '] given');
                }
                return integer;
            },
    
            /**
             * Check whether the given parameter is a plain object (array and functions aren't accepted)
             * @param {Object} object
             * @returns {Object} object
             * @throws {TypeError} for non object
             */
            plainObject : function(object){
                if(typeof object !== 'object' || object.constructor !== Object){
                    throw new TypeError('an object is expected, but ' + object + '  [' + (typeof object) + '] given');
                }
                return object;
            },
    
            /**
             * Check whether the given parameter is a type supported by Aja.
             * The list of supported types is set above, in the {@link types} variable.
             * @param {String} type
             * @returns {String} type
             * @throws {TypeError} if the type isn't supported
             */
            type : function(type){
                type = this.string(type);
                if(types.indexOf(type.toLowerCase()) < 0){
                    throw new TypeError('a type in [' + types.join(', ') + '] is expected, but ' + type + ' given');
                }
                return type.toLowerCase();
            },
    
            /**
             * Check whether the given HTTP method is supported.
             * The list of supported methods is set above, in the {@link methods} variable.
             * @param {String} method
             * @returns {String} method (but to lower case)
             * @throws {TypeError} if the method isn't supported
             */
            method : function(method){
                method = this.string(method);
                if(methods.indexOf(method.toLowerCase()) < 0){
                    throw new TypeError('a method in [' + methods.join(', ') + '] is expected, but ' + method + ' given');
                }
                return method.toLowerCase();
            },
    
            /**
             * Check the queryString, and create an object if a string is given.
             *
             * @param {String|Object} params
             * @returns {Object} key/value based queryString
             * @throws {TypeError} if wrong params type or if the string isn't parseable
             */
            queryString : function(params){
                var object = {};
                if(typeof params === 'string'){
    
                    params.replace('?', '').split('&').forEach(function(kv){
                        var pair = kv.split('=');
                        if(pair.length === 2){
                            object[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
                        }
                    });
                } else {
                    object = params;
                }
                return this.plainObject(object);
            },
    
            /**
             * Check if the parameter enables us to select a DOM Element.
             *
             * @param {String|HTMLElement} selector - CSS selector or the element ref
             * @returns {String|HTMLElement} same as input if valid
             * @throws {TypeError} check it's a string or an HTMLElement
             */
            selector : function(selector){
                if(typeof selector !== 'string' && !(selector instanceof HTMLElement)){
                    throw new TypeError('a selector or an HTMLElement is expected, ' + selector + ' [' + (typeof selector) + '] given');
                }
                return selector;
            },
    
            /**
             * Check if the parameter is a valid JavaScript function name.
             *
             * @param {String} functionName
             * @returns {String} same as input if valid
             * @throws {TypeError} check it's a string and a valid name against the pattern inside.
             */
            func : function(functionName){
                functionName = this.string(functionName);
                if(!/^([a-zA-Z_])([a-zA-Z0-9_\-])+$/.test(functionName)){
                    throw new TypeError('a valid function name is expected, ' + functionName + ' [' + (typeof functionName) + '] given');
                }
                return functionName;
            }
        };
    
        /**
         * Query string helper : append some parameters
         * @private
         * @param {String} url - the URL to append the parameters
         * @param {Object} params - key/value
         * @returns {String} the new URL
         */
        var appendQueryString = function appendQueryString(url, params){
            var key;
            url = url || '';
            if(params){
                if(url.indexOf('?') === -1){
                    url += '?';
                }
                if(typeof params === 'string'){
                    url += params;
                } else if (typeof params === 'object'){
                    for(key in params){
                        url += '&' + encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
                    }
                }
            }
    
            return url;
        };
    
        //AMD, CommonJs, then globals
        // if (typeof define === 'function' && define.amd) {
        //     define([], function(){
        //         return aja;
        //     });
        // } else if (typeof exports === 'object') {
        //     module.exports = aja;
        // } else {
        //     window.aja = window.aja || aja;
        // }
    
        return {
            aja: aja
        }
    
    }());
    
    /**
     * Base settings
     */
    const
    configStarterBase = {
        'localStorageReferrerName': 'ektracker_referrer',
        'localStorageRG0': 'ektracker_rg0',
        'localStorageRG1': 'ektracker_rg1',
        'localStorageRG2': 'ektracker_rg2',
        'initScriptLocalStorageNameReferrer': 'ecr',
        'initScriptLocalStorageNameFirstUrl': 'ecu'
    };
    // starter script storage
    var starterStorage =
        (function () {
            var add = function(data) {
                localStorage.setItem(configStarterBase.localStorageName, data);
            }
            var get = function() {
                let data = localStorage.getItem(configStarterBase.localStorageName);
                empty();
                return (data === undefined || data === null)?false:data;
            }
            var empty = function() {
                localStorage.removeItem(configStarterBase.localStorageName);
            }
            return {
                add: add,
                get: get,
                empty: empty
            }
        })();

    var
        /**
         * ID for website
         * @type {boolean|string}
         */
        webID = false,
        /**
         * init Date object for tracking time on page
         * @type {Date}
         */
        initDate,
        /**
         * ID for update request
         * @type {boolean|number}
         */
        callbackIDForUpdateData = false,
        /**
         * Calculated time spent on the page
         * @type {number}
         */
        totalTimeSpentOnThePage = 0;

    // algorithm manager
    var algorithm =
        (function () {
            var md5 = function(string) {
                var MD5;
                // MD5 (Message-Digest Algorithm) http://www.webtoolkit.info/
                MD5 = function (string) {
                
                    function RotateLeft(lValue, iShiftBits) {
                        return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));
                    }
                
                    function AddUnsigned(lX,lY) {
                        var lX4,lY4,lX8,lY8,lResult;
                        lX8 = (lX & 0x80000000);
                        lY8 = (lY & 0x80000000);
                        lX4 = (lX & 0x40000000);
                        lY4 = (lY & 0x40000000);
                        lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);
                        if (lX4 & lY4) {
                            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);
                        }
                        if (lX4 | lY4) {
                            if (lResult & 0x40000000) {
                                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);
                            } else {
                                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);
                            }
                        } else {
                            return (lResult ^ lX8 ^ lY8);
                        }
                    }
                
                    function F(x,y,z) { return (x & y) | ((~x) & z); }
                
                    function G(x,y,z) { return (x & z) | (y & (~z)); }
                
                    function H(x,y,z) { return (x ^ y ^ z); }
                
                    function I(x,y,z) { return (y ^ (x | (~z))); }
                
                    function FF(a,b,c,d,x,s,ac) {
                        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));
                        return AddUnsigned(RotateLeft(a, s), b);
                    };
                
                    function GG(a,b,c,d,x,s,ac) {
                        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));
                        return AddUnsigned(RotateLeft(a, s), b);
                    };
                
                    function HH(a,b,c,d,x,s,ac) {
                        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));
                        return AddUnsigned(RotateLeft(a, s), b);
                    };
                
                    function II(a,b,c,d,x,s,ac) {
                        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));
                        return AddUnsigned(RotateLeft(a, s), b);
                    };
                
                    function ConvertToWordArray(string) {
                        var lWordCount;
                        var lMessageLength = string.length;
                        var lNumberOfWords_temp1=lMessageLength + 8;
                        var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;
                        var lNumberOfWords = (lNumberOfWords_temp2+1)*16;
                        var lWordArray=Array(lNumberOfWords-1);
                        var lBytePosition = 0;
                        var lByteCount = 0;
                        while ( lByteCount < lMessageLength ) {
                            lWordCount = (lByteCount-(lByteCount % 4))/4;
                            lBytePosition = (lByteCount % 4)*8;
                            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));
                            lByteCount++;
                        }
                        lWordCount = (lByteCount-(lByteCount % 4))/4;
                        lBytePosition = (lByteCount % 4)*8;
                        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);
                        lWordArray[lNumberOfWords-2] = lMessageLength<<3;
                        lWordArray[lNumberOfWords-1] = lMessageLength>>>29;
                        return lWordArray;
                    };
                
                    function WordToHex(lValue) {
                        var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;
                        for (lCount = 0;lCount<=3;lCount++) {
                            lByte = (lValue>>>(lCount*8)) & 255;
                            WordToHexValue_temp = "0" + lByte.toString(16);
                            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);
                        }
                        return WordToHexValue;
                    };
                
                    function Utf8Encode(string) {
                        string = string.replace(/\r\n/g,"\n");
                        var utftext = "";
                        for (var n = 0; n < string.length; n++) {
                            var c = string.charCodeAt(n);
                            if (c < 128) {
                                utftext += String.fromCharCode(c);
                            }
                            else if((c > 127) && (c < 2048)) {
                                utftext += String.fromCharCode((c >> 6) | 192);
                                utftext += String.fromCharCode((c & 63) | 128);
                            }
                            else {
                                utftext += String.fromCharCode((c >> 12) | 224);
                                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                                utftext += String.fromCharCode((c & 63) | 128);
                            }
                        }
                        return utftext;
                    };
                
                    var x=Array();
                    var k,AA,BB,CC,DD,a,b,c,d;
                    var S11=7, S12=12, S13=17, S14=22;
                    var S21=5, S22=9 , S23=14, S24=20;
                    var S31=4, S32=11, S33=16, S34=23;
                    var S41=6, S42=10, S43=15, S44=21;
                    string = Utf8Encode(string);
                    x = ConvertToWordArray(string);
                    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;
                    for (k=0;k<x.length;k+=16) {
                        AA=a; BB=b; CC=c; DD=d;
                        a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);
                        d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);
                        c=FF(c,d,a,b,x[k+2], S13,0x242070DB);
                        b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);
                        a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);
                        d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);
                        c=FF(c,d,a,b,x[k+6], S13,0xA8304613);
                        b=FF(b,c,d,a,x[k+7], S14,0xFD469501);
                        a=FF(a,b,c,d,x[k+8], S11,0x698098D8);
                        d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);
                        c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);
                        b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);
                        a=FF(a,b,c,d,x[k+12],S11,0x6B901122);
                        d=FF(d,a,b,c,x[k+13],S12,0xFD987193);
                        c=FF(c,d,a,b,x[k+14],S13,0xA679438E);
                        b=FF(b,c,d,a,x[k+15],S14,0x49B40821);
                        a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);
                        d=GG(d,a,b,c,x[k+6], S22,0xC040B340);
                        c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);
                        b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);
                        a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);
                        d=GG(d,a,b,c,x[k+10],S22,0x2441453);
                        c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);
                        b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);
                        a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);
                        d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);
                        c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);
                        b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);
                        a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);
                        d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);
                        c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);
                        b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);
                        a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);
                        d=HH(d,a,b,c,x[k+8], S32,0x8771F681);
                        c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);
                        b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);
                        a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);
                        d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);
                        c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);
                        b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);
                        a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);
                        d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);
                        c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);
                        b=HH(b,c,d,a,x[k+6], S34,0x4881D05);
                        a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);
                        d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);
                        c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);
                        b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);
                        a=II(a,b,c,d,x[k+0], S41,0xF4292244);
                        d=II(d,a,b,c,x[k+7], S42,0x432AFF97);
                        c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);
                        b=II(b,c,d,a,x[k+5], S44,0xFC93A039);
                        a=II(a,b,c,d,x[k+12],S41,0x655B59C3);
                        d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);
                        c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);
                        b=II(b,c,d,a,x[k+1], S44,0x85845DD1);
                        a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);
                        d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);
                        c=II(c,d,a,b,x[k+6], S43,0xA3014314);
                        b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);
                        a=II(a,b,c,d,x[k+4], S41,0xF7537E82);
                        d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);
                        c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);
                        b=II(b,c,d,a,x[k+9], S44,0xEB86D391);
                        a=AddUnsigned(a,AA);
                        b=AddUnsigned(b,BB);
                        c=AddUnsigned(c,CC);
                        d=AddUnsigned(d,DD);
                    }
                    var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);
                    return temp.toLowerCase();
                }
                return MD5(string);
            }
            var sha1 = function(string) {
                var SHA1;
                // Secure Hash Algorithm (SHA1) http://www.webtoolkit.info/
                SHA1 = function (msg) {
                
                    function rotate_left(n,s) {
                        var t4 = ( n<<s ) | (n>>>(32-s));
                        return t4;
                    };
                
                    function lsb_hex(val) {
                        var str="";
                        var i;
                        var vh;
                        var vl;
                        for( i=0; i<=6; i+=2 ) {
                            vh = (val>>>(i*4+4))&0x0f;
                            vl = (val>>>(i*4))&0x0f;
                            str += vh.toString(16) + vl.toString(16);
                        }
                        return str;
                    };
                
                    function cvt_hex(val) {
                        var str="";
                        var i;
                        var v;
                        for( i=7; i>=0; i-- ) {
                            v = (val>>>(i*4))&0x0f;
                            str += v.toString(16);
                        }
                        return str;
                    };
                
                    function Utf8Encode(string) {
                        string = string.replace(/\r\n/g,"\n");
                        var utftext = "";
                        for (var n = 0; n < string.length; n++) {
                            var c = string.charCodeAt(n);
                            if (c < 128) {
                                utftext += String.fromCharCode(c);
                            }
                            else if((c > 127) && (c < 2048)) {
                                utftext += String.fromCharCode((c >> 6) | 192);
                                utftext += String.fromCharCode((c & 63) | 128);
                            }
                            else {
                                utftext += String.fromCharCode((c >> 12) | 224);
                                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                                utftext += String.fromCharCode((c & 63) | 128);
                            }
                        }
                        return utftext;
                    };
                    var blockstart;
                    var i, j;
                    var W = new Array(80);
                    var H0 = 0x67452301;
                    var H1 = 0xEFCDAB89;
                    var H2 = 0x98BADCFE;
                    var H3 = 0x10325476;
                    var H4 = 0xC3D2E1F0;
                    var A, B, C, D, E;
                    var temp;
                    msg = Utf8Encode(msg);
                    var msg_len = msg.length;
                    var word_array = new Array();
                    for( i=0; i<msg_len-3; i+=4 ) {
                        j = msg.charCodeAt(i)<<24 | msg.charCodeAt(i+1)<<16 |
                            msg.charCodeAt(i+2)<<8 | msg.charCodeAt(i+3);
                        word_array.push( j );
                    }
                    switch( msg_len % 4 ) {
                        case 0:
                            i = 0x080000000;
                            break;
                        case 1:
                            i = msg.charCodeAt(msg_len-1)<<24 | 0x0800000;
                            break;
                        case 2:
                            i = msg.charCodeAt(msg_len-2)<<24 | msg.charCodeAt(msg_len-1)<<16 | 0x08000;
                            break;
                        case 3:
                            i = msg.charCodeAt(msg_len-3)<<24 | msg.charCodeAt(msg_len-2)<<16 | msg.charCodeAt(msg_len-1)<<8    | 0x80;
                            break;
                    }
                    word_array.push( i );
                    while( (word_array.length % 16) != 14 ) word_array.push( 0 );
                    word_array.push( msg_len>>>29 );
                    word_array.push( (msg_len<<3)&0x0ffffffff );
                    for ( blockstart=0; blockstart<word_array.length; blockstart+=16 ) {
                        for( i=0; i<16; i++ ) W[i] = word_array[blockstart+i];
                        for( i=16; i<=79; i++ ) W[i] = rotate_left(W[i-3] ^ W[i-8] ^ W[i-14] ^ W[i-16], 1);
                        A = H0;
                        B = H1;
                        C = H2;
                        D = H3;
                        E = H4;
                        for( i= 0; i<=19; i++ ) {
                            temp = (rotate_left(A,5) + ((B&C) | (~B&D)) + E + W[i] + 0x5A827999) & 0x0ffffffff;
                            E = D;
                            D = C;
                            C = rotate_left(B,30);
                            B = A;
                            A = temp;
                        }
                        for( i=20; i<=39; i++ ) {
                            temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0x6ED9EBA1) & 0x0ffffffff;
                            E = D;
                            D = C;
                            C = rotate_left(B,30);
                            B = A;
                            A = temp;
                        }
                        for( i=40; i<=59; i++ ) {
                            temp = (rotate_left(A,5) + ((B&C) | (B&D) | (C&D)) + E + W[i] + 0x8F1BBCDC) & 0x0ffffffff;
                            E = D;
                            D = C;
                            C = rotate_left(B,30);
                            B = A;
                            A = temp;
                        }
                        for( i=60; i<=79; i++ ) {
                            temp = (rotate_left(A,5) + (B ^ C ^ D) + E + W[i] + 0xCA62C1D6) & 0x0ffffffff;
                            E = D;
                            D = C;
                            C = rotate_left(B,30);
                            B = A;
                            A = temp;
                        }
                        H0 = (H0 + A) & 0x0ffffffff;
                        H1 = (H1 + B) & 0x0ffffffff;
                        H2 = (H2 + C) & 0x0ffffffff;
                        H3 = (H3 + D) & 0x0ffffffff;
                        H4 = (H4 + E) & 0x0ffffffff;
                    }
                    var temp = cvt_hex(H0) + cvt_hex(H1) + cvt_hex(H2) + cvt_hex(H3) + cvt_hex(H4);
                    return temp.toLowerCase();
                }
                return SHA1(string);
            }
            return {
                md5: md5,
                sha1: sha1
            }
        })();

    // tools manager
    var tools =
        (function () {
            var randomString = function(length) {
                let
                    result = '',
                    characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789',
                    charactersLength = characters.length;
                for(let i = 0; i < length; i++ ) {
                    result += characters.charAt(Math.floor(Math.random() * charactersLength));
                }
                return result;
            }
            var calculateDiffDateInSeconds = function(start, end) {
                return Math.ceil((end.getTime() - start.getTime()) / 1000);
            }
            var getRequestToken = function() {
                /**
                 * date.getFullYear(): year (for ex. 2021)
                 * date.getMonth(): month (from 0-11)
                 * date.getDate(): day of the month (from 1-31)
                 * date.getHours(): hour (from 0-23)
                 */
                let date = new Date();
                return algorithm.sha1((window.location.hostname + user.getUserID() + date.getFullYear() + date.getMonth() + date.getDate() + date.getHours()));
            }
            var debug = function (someData) {
                if(configMode.debug) {
                    console.log(someData);
                }
            }
            var isThisSomeID = function(possibleID) {
                return parseInt(possibleID) > 0;
            }
            return {
                randomString: randomString,
                calculateDiffDateInSeconds: calculateDiffDateInSeconds,
                getRequestToken: getRequestToken,
                debug: debug,
                isThisSomeID: isThisSomeID
            }
        })();
    // data mapper
    var dataMapper =
        (function () {
            var
                prefixDivider = '_';
            var makeFlattenArray = function (prefix, sourceArray, outArray) {
                if(prefix !== 'products' && typeof sourceArray === 'object') {
    
                    if(prefix.slice(-1) !== prefixDivider){
                        prefix = prepareFlattenPrefix(prefix);
                    }
                    for(let k in sourceArray){
                        if(sourceArray.hasOwnProperty(k)){
                            let actualPrefix = prefix + k;
                            if(typeof sourceArray[k] === 'object') {
                                makeFlattenArray(prepareFlattenPrefix(actualPrefix), sourceArray[k], outArray)
                            }else {
                                outArray[actualPrefix] = sourceArray[k];
                            }
                        }
                    }
    
                } else {
                    outArray[prefix] = sourceArray;
                }
                return outArray;
            }
            var prepareFlattenPrefix = function (string) {
                return string + prefixDivider;
            }
            return {
                makeFlattenArray: makeFlattenArray,
                prepareFlattenPrefix: prepareFlattenPrefix
            }
        })();
    // cookie manager
    var cookie =
        (function () {
            var get = function(name) {
                let
                    cookieName = name + "=",
                    listCookie = document.cookie.split(';');
                for(let i = 0; i < listCookie.length; i++) {
                    let c = listCookie[i];
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(cookieName) == 0) {
                        return c.substring(cookieName.length, c.length);
                    }
                }
                return false;
            }
            var set = function(name, value, expiractionInDays) {
                const d = new Date();
                d.setTime(d.getTime() + (expiractionInDays*24*60*60*1000));
                let expires = "expires="+ d.toUTCString();
                document.cookie = name + "=" + value + ";" + expires + ";path=/";
            }
            return {
                get: get,
                set: set
            }
        })();
    // user manager
    var user =
        (function () {
            var
                /**
                 * @type {string|boolean}
                 */
                userID = false,
                /**
                 * email addr. when is logged in | false when not logged in
                 * @type {string|boolean}
                 */
                loggedUser = false;
    
            var setLoggedUser = function(email) {
                loggedUser = email;
            }
            var getLoggedUser = function() {
                return loggedUser;
            }
            var getUserID = function() {
                if(userID === false) {
                    setUserID();
                }
                return userID;
            }
            var setUserID = function() {
                // get existing user ID from cookie or generate new one
                userID = cookie.get(configCookieVariables.userID.name);
                // userID not exist => generate new one
                if(userID === false){
                    userID = getNewUserID();
                    cookie.set(configCookieVariables.userID.name, userID, configCookieVariables.userID.expiration);
                }
            }
            var getNewUserID = function() {
                /*
                    composed from:
                    - user agent
                    - Date.getTime()
                    - 5 random chars
                    - url of website = window.location.href
                 */
                const
                    date = new Date(),
                    string = navigator.userAgent + date.getTime() + tools.randomString(5) + window.location.href;
                return algorithm.md5(string);
            }
            return {
                getUserID: getUserID,
                setUserID: setUserID,
                setLoggedUser: setLoggedUser,
                getLoggedUser: getLoggedUser
            }
        })();
    // event manager
    var event =
        (function () {
            var eventCatcher = function() {
                process(arguments)
            }
            var processTimeSpendOnPageQueue = function(arrData) {
                for(let i = 0; i < arrData.length; i++) {
                    eventCatcher('timeSpendOnPage', arrData[i].time, arrData[i].id);
                }
            }
            var processExistedQueue = function(arrQueue) {
                if(arrQueue && arrQueue.length > 0) {
                    for(let i = 0;i < arrQueue.length; i++) {
                        process(arrQueue[i]);
                    }
                }
            }
            function process(parameters) {
                if(robots.amIRobot() === true) {
                    return;
                }
                if(parameters.length === 0){
                    return;
                }
                if(existThisEvent(parameters[0]) === false){
                    return;
                }
                const
                    event = parameters[0],
                    eventConfiguration = configEvent[event];
                if(eventConfiguration.expectedNumberOfParameters !== parameters.length) {
                    return;
                }
                switch(eventConfiguration.type) {
                    case configEventType.init:
                        webID = parameters[1];
                        break;
                    case configEventType.saveLoggedUser:
                        user.setLoggedUser(parameters[1]);
                        break;
                    case configEventType.sendData:
                        sendData(event, eventConfiguration, parameters);
                        break;
                    default:
                        return;
                }
            }
            function existThisEvent(event) {
                return configEvent.hasOwnProperty(event);
            }
            function sendData(event, eventConfiguration, parameters) {
                let data = configBase.baseRequestData();
                data.event = event;
                data.web_id = webID;
                data.user_id = user.getUserID();
                // we dont send data without generated user ID
                if(data.user_id === false) {
                    return;
                }
                data.user_is_logged = user.getLoggedUser();
                data.token = tools.getRequestToken();
                if(eventConfiguration.hasOwnProperty('dataMapper')){
                    const
                        eventData = eventConfiguration.dataMapper(parameters);
                    for(let k in eventData){
                        if(eventData.hasOwnProperty(k)){
                            dataMapper.makeFlattenArray(k, eventData[k], data);
                        }
                    }
                }
                request.submit(eventConfiguration.urlEndpoint, data);
            }
            return {
                eventCatcher: eventCatcher,
                processExistedQueue: processExistedQueue,
                processTimeSpendOnPageQueue: processTimeSpendOnPageQueue
            }
        })();
    // request manager
    var request =
        (function () {
            var submit = function(urlEndPoint, data) {
    
                // debugging(urlEndPoint, data);
    
                aja.aja()
                    .method('POST')
                    .type('json')
                    .url(getFinalUrl(urlEndPoint))
                    .body(attributeShortcut.process(data))
                    .on('200', function(dataFromResponse){
                        // tools.debug('Ajax reponse code: 200');
                        if(tools.isThisSomeID(dataFromResponse)) {
                            callbackIDForUpdateData = dataFromResponse;
                        }
                    })
                    // .on('204', function(r){
                    //     tools.debug('Ajax reponse code: 204');
                    // })
                    .go();
    
            }
            function getFinalUrl(urlEndPoint) {
                return configMode.baseUrl + urlEndPoint;
            }
            function debugging(urlEndPoint, data){
                console.log(urlEndPoint);
                console.log(data);
                console.log('--------------------');
            }
            return {
                submit: submit
            }
        })();
    // referrer manager (history)
    var referrer =
        (function () {
    
            var get = function() {
                // first we try find referrer from starter script,
                // which can not be blocked by cookie bar
                let
                    starterStorageReferrer = starterStorage.get(),
                    url = starterStorageReferrer!==false?starterStorageReferrer:document.referrer;
                if(url.length === 0){
                    return false;
                }
                if(isThisInternalUrl(url)) {
                    return false;
                }
                return url;
            }
            var isThisInternalUrl = function(url) {
                let urlObject = new ParsedUrl(url);
                return urlObject.hostname === location.hostname;
            }
            // help from: https://stackoverflow.com/a/38557322
            function ParsedUrl(url) {
                var parser = document.createElement("a");
                parser.href = url;
    
                // IE 8 and 9 dont load the attributes "protocol" and "host" in case the source URL
                // is just a pathname, that is, "/example" and not "http://domain.com/example".
                parser.href = parser.href;
    
                // IE 7 and 6 wont load "protocol" and "host" even with the above workaround,
                // so we take the protocol/host from window.location and place them manually
                if (parser.host === "") {
                    var newProtocolAndHost = window.location.protocol + "//" + window.location.host;
                    if (url.charAt(1) === "/") {
                        parser.href = newProtocolAndHost + url;
                    } else {
                        // the regex gets everything up to the last "/"
                        // /path/takesEverythingUpToAndIncludingTheLastForwardSlash/thisIsIgnored
                        // "/" is inserted before because IE takes it of from pathname
                        var currentFolder = ("/"+parser.pathname).match(/.*\//)[0];
                        parser.href = newProtocolAndHost + currentFolder + url;
                    }
                }
    
                // copies all the properties to this object
                var properties = ['host', 'hostname', 'hash', 'href', 'port', 'protocol', 'search'];
                for (var i = 0, n = properties.length; i < n; i++) {
                    this[properties[i]] = parser[properties[i]];
                }
    
                // pathname is special because IE takes the "/" of the starting of pathname
                this.pathname = (parser.pathname.charAt(0) !== "/" ? "/" : "") + parser.pathname;
            }
            return {
                get: get,
                isThisInternalUrl: isThisInternalUrl
            }
        })();
    // robots manager
    var robots =
        (function () {
            var
                amIRobotResult = false,
                checkIfIAmRobot = false;
            var amIRobot = function() {
                if(checkIfIAmRobot === false) {
                    checkIfIAmRobot = true;
                    let
                        userAgent = navigator.userAgent;
                    for(let i = 0; i < configRobots.length; i++) {
                        if(RegExp(configRobots[i]).test(userAgent)) {
                            amIRobotResult = true;
                            break;
                        }
                    }
                }
                return amIRobotResult;
            }
            return {
                amIRobot: amIRobot
            }
        })();
    // attribute shortcut manager
    var attributeShortcut =
        (function () {
    
            var productVariant = function (out, data, variantShortut) {
    
                if(typeof data === 'object') {
    
                    for(let k in data) {
                        let group = {};
    
                        if (data.hasOwnProperty(k)) {
    
                            Object.keys(data[k]).forEach(function(ki) {
    
                                if(configAttributeShortcut.productsVariant.attributes.hasOwnProperty(ki)) {
    
                                    group[configAttributeShortcut.productsVariant.attributes[ki]] = data[k][ki];
    
                                }
    
                            });
    
                        }
    
                        out[variantShortut].push(group);
                    }
    
                }
    
            }
    
            var product = function(data) {
                let
                    out = [],
                    variantShortut = configAttributeShortcut.productsVariant.shortcut;
    
                if(typeof data === 'object') {
    
                    for(let kpi in data){
                        if(data.hasOwnProperty(kpi)){
    
                            let oneProductData = {};
    
                            if(typeof data[kpi] === 'object') {
    
                                Object.keys(data[kpi]).forEach(function(kc) {
    
                                    if(configAttributeShortcut.products.attributes.hasOwnProperty(kc)) {
    
                                        if(kc === 'variant') {
    
                                            oneProductData[variantShortut] = [];
                                            productVariant(oneProductData, data[kpi][kc], variantShortut);
    
    
                                        } else {
    
                                            oneProductData[configAttributeShortcut.products.attributes[kc]] = data[kpi][kc];
    
                                        }
    
                                    }
    
                                });
    
                            }
    
                            out.push(oneProductData);
    
                        }
                    }
    
                }
    
                return out;
            }
    
            var loop = function(out, data, key, prevShortcut = '') {
    
                if(configAttributeShortcut.basic.hasOwnProperty(key)) {
    
                    out[configAttributeShortcut.basic[key]] = data;
    
                } else {
    
                    if(key === 'products') {
    
                        const productsKey = configAttributeShortcut.products.shortcut;
                        out[productsKey] = product(data, productsKey);
    
                    } else {
    
                        Object.keys(configAttributeShortcut.loops).forEach(function(k2) {
    
                            if(key.indexOf(k2) === 0) {
    
                                let
                                    rule = configAttributeShortcut.loops[k2],
                                    arrK = key.split(rule.divider),
                                    attribute = arrK.slice(2, arrK.length).join(rule.divider),
                                    actualShortcut = prevShortcut + rule.shortcut + arrK[1];
    
                                if(rule.attributes.hasOwnProperty(attribute)) {
    
                                    out[(actualShortcut + rule.attributes[attribute])] = data;
    
                                } else {
    
                                    Object.keys(configAttributeShortcut.loops).forEach(function(k3) {
    
                                        if(attribute.indexOf(k3) === 0) {
    
                                            out = loop(out, data, attribute, actualShortcut);
    
                                        }
    
                                    });
    
                                }
                            }
                        });
    
                    }
    
                }
    
                return out;
            };
    
            var process  = function(data) {
                let out = {};
    
                Object.keys(data).forEach(function(k) {
    
                    out = loop(out, data[k], k);
    
                });
    
                return out;
            }
            return {
                process: process
            }
        })();
    // time spend on page manager
    var timeSpendOnPage =
        (function () {
            var
                nameSpaceForLocalStorage = 'ektracker_timeSpendOnPage';
            var add = function(callbackID, time) {
                let data = getFromLocalStorage();
                if(data === false) {
                    data = [];
                }
                data.push(createNewOne(callbackID, time));
                saveToLocalStorage(data);
            }
            var getListOfUnsent = function() {
                let data = getFromLocalStorage();
                emptyLocalStorage();
                return data === false?[]:data;
            }
            function createNewOne(callbackID, time) {
                return {
                    'id': callbackID,
                    'time': time
                };
            }
            function emptyLocalStorage() {
                localStorage.removeItem(nameSpaceForLocalStorage);
            }
            function getFromLocalStorage() {
                let data = localStorage.getItem(nameSpaceForLocalStorage);
                return (data === undefined || data === null)?false:JSON.parse(data);
            }
            function saveToLocalStorage(data) {
                localStorage.setItem(nameSpaceForLocalStorage, JSON.stringify(data));
            }
            return {
                add: add,
                getListOfUnsent: getListOfUnsent
            }
        })();

    // Shoptet
    var shoptet =
        (function () {
    
            var
                isShoptet = false,
                eventManager;
    
            var init  = function(event) {
    
                eventManager = event;
    
                if(isThisShoptet()) {
    
                    const shoptetDataLayer = dataLayer[0].shoptet;
    
                    // event pro add / remove from cart
                    document.addEventListener('ShoptetDataLayerUpdated', function() {
    
                        const
                            shoptetDataLayer = getShoptetDataLayer(),
                            gtmDataLayer = dataLayer[dataLayer.length -1],
                            pageType = shoptetDataLayer.pageType,
                            event = gtmDataLayer.event;
    
                        if(pageType === 'cart') {
                            //udelame update polozky z kosiku nebo remove
                            eventUpdateToCart(shoptetDataLayer, gtmDataLayer);
                        } else if(event === 'addToCart') {
                            // pridani polozky do kosiku
                            eventAddToCart(shoptetDataLayer, gtmDataLayer);
                        }
    
                    });
    
                    if(shoptetDataLayer.pageType === 'thankYou') {
                        // odeslani objednavky
                        eventSubmitOrder(shoptetDataLayer);
                    }
    
                }
    
            }
    
            function isThisShoptet() {
                return typeof getShoptetDataLayer === "function";
            }
    
            var registerLoggedCustomer = function(eventManager){
    
                try {
    
                    if(isThisShoptet()) {
    
                        const shoptetDataLayer = dataLayer[0].shoptet;
    
                        if(shoptetDataLayer && typeof shoptetDataLayer === 'object' && shoptetDataLayer.hasOwnProperty('customer')) {
    
                            const customerData = shoptetDataLayer.customer;
    
                            if(typeof customerData === 'object' && customerData.hasOwnProperty('email') && customerData.email) {
    
                                eventManager.eventCatcher('loggedUser', customerData.email);
    
                            }
    
    
                        }
    
                    }
    
                } catch (error) {
    
                }
    
            }
    
            function makeArrayFromVariantString(string) {
                let out = [];
    
                if(string) {
    
                    const
                        paramsSplitChar = ',',
                        paramsJoinChar = ', ',
                        valueParamSplitChar = ':';
    
                    let
                        tempString = [],
                        tempArr = [];
    
                    string.split(paramsSplitChar).forEach((one) => {
    
                        const
                            parameterString = one.trim();
    
                        if(parameterString.indexOf(valueParamSplitChar) > -1) {
    
                            tempArr.push(tempString.join(paramsJoinChar) + (tempString.length > 0 ? paramsJoinChar : '') + parameterString);
                            tempString = [];
    
                        } else {
    
                            if(tempArr.length === 0) {
    
                                tempString.push(parameterString);
    
                            } else {
    
                                let
                                    prevIndex = tempArr.length - 1,
                                    prevString = tempArr[prevIndex];
    
                                tempArr[prevIndex] = prevString + paramsJoinChar.trim() + one;
    
                            }
    
                        }
    
                    });
    
    
                    tempArr.forEach((one) => {
    
                        const parameterValue = one.split(valueParamSplitChar).map((one) => one.trim());
    
                        if(parameterValue.length === 2) {
    
                            out.push({
                                'name': parameterValue[0],
                                'value': parameterValue[1]
                            });
    
                        }
    
                    });
    
                }
    
                return out;
            }
    
            function eventUpdateToCart(shoptetDataLayer, gtmDataLayer) {
    
                let
                    event = gtmDataLayer.event,
                    productCode = event === 'removeFromCart' ? gtmDataLayer.ecommerce.remove[0].id : gtmDataLayer.ecommerce.add[0].id,
                    productInCart = shoptetDataLayer.cart.find((one) => one.code === productCode);
    
                if(productInCart) {
    
                    eventManager.eventCatcher(
                        'updateCart',
                        {
                            'products': [
                                {
                                    'code': productCode,
                                    'quantity': productInCart.quantity
                                }
                            ]
                        }
                    );
    
                } else {
    
                    eventManager.eventCatcher(
                        'removeFromCart',
                        {
                            'products': [
                                {
                                    'code': productCode
                                }
                            ]
                        }
                    );
    
                }
    
            }
    
            function eventSubmitOrder(shoptetDataLayer) {
    
                var listProducts = [];
                shoptetDataLayer.order.content.forEach((oneProduct) => {
    
                    listProducts.push(
                        {
                            'code': oneProduct.sku,
                            'name': oneProduct.name,
                            'image': '',
                            'url': '',
                            'variant': (oneProduct.hasOwnProperty('variant') ? makeArrayFromVariantString(oneProduct.variant) : []),
                            'quantity': oneProduct.quantity,
                            'price_no_vat': '',
                            'vat': '',
                            'price_with_vat': oneProduct.price
                        }
                    );
    
                });
    
                eventManager.eventCatcher(
                    'orderSubmitted',
                    {
                        'sourceShopType': 1,
                        'order': {
                            'number': shoptetDataLayer.order.orderNo,
                            'currency': shoptetDataLayer.currencyCode,
                            'total_price_no_vat': '',
                            'total_price_with_vat': shoptetDataLayer.order.total,
                            'delivery': {
                                'name': '',
                                'price_no_vat': '',
                                'vat': '',
                                'price_with_vat': ''
                            },
                            'payment': {
                                'name': '',
                                'price_no_vat': '',
                                'vat': '',
                                'price_with_vat': ''
                            }
                        },
                        'customer': {
                            'name': '',
                            'surname': '',
                            'phone': '',
                            'email': '',
                            'street': '',
                            'town': '',
                            'postal_code': '',
                            'delivery_address': {
                                'name': '',
                                'surname': '',
                                'street': '',
                                'town': '',
                                'postal_code': ''
                            }
                        },
                        'products': listProducts
                    }
                );
    
            }
    
            function eventAddToCart(shoptetDataLayer, gtmDataLayer) {
    
                eventManager.eventCatcher(
                    'addToCart',
                    {
                        'currency': getCurrencyCode(gtmDataLayer),
                        'products': [
                            getProduct(gtmDataLayer.ecommerce.add[0])
                        ]
                    }
                );
    
            }
    
            function getProduct(data) {
    
                return {
                    'code': data.id,
                    'name': data.name,
                    'image': '',
                    'url': '',
                    'variant': (data.hasOwnProperty('variant') ? makeArrayFromVariantString(data.variant) : []),
                    'quantity': data.quantity,
                    'price_no_vat': '',
                    'vat': '',
                    'price_with_vat': data.price
                }
    
            }
    
            function getCurrencyCode(gtmDataLayer) {
                return gtmDataLayer.ecommerce.currencyCode
            }
    
            return {
                init: init,
                registerLoggedCustomer: registerLoggedCustomer
            }
        })();

    // function for event when user leaving page
    function eventBeforeUnload() {
        // save data to localstorage
        if(callbackIDForUpdateData) {
            timeSpendOnPage.add(callbackIDForUpdateData, tools.calculateDiffDateInSeconds(initDate, new Date()));
        }
    }

    // save init date
    initDate = existingFunction.t;

    // try to catch logged user from Shoptet
    shoptet.registerLoggedCustomer(event);

    // processing already exist submitted events
    event.processExistedQueue(existingFunction.q);
    // replacing the anonymous function from init function from loaded script
    window[functionName] = event.eventCatcher;
    // add event beforeunload (user leaving page..)
    window.addEventListener("beforeunload", function () {
        eventBeforeUnload();
    });
    // processing existing queue on timeSpendEvent in localstorage
    event.processTimeSpendOnPageQueue(timeSpendOnPage.getListOfUnsent());

    // integration
    shoptet.init(event);

})(window);

